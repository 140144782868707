<template>
  <v-row justify="center" class="ma-0">
    <v-col cols="12" class="article-container bg-section">
      <div v-if="article.name" class="article">
        <h1 data-testid="article-title">{{ article.name }}</h1>
        <v-row class="ma-0">
          <v-avatar> <v-img :src="article.avatar" /> </v-avatar>
          <v-col data-testid="author-data" class="py-0">
            <h4>{{ article.author }}</h4>
            <p class="text-grey subtitle">
              {{ article.createdAt.toLocaleDateString() }} ·
              {{ article.timeToRead }} min read
            </p>
          </v-col>
          <v-col align="end" class="py-0">
            <app-button
              v-if="editRouterLink"
              :icon="mdiPencil"
              color="success"
              variant="text"
              density="comfortable"
              data-testid="edit-article"
              :to="editRouterLink"
            />
            <v-tooltip v-if="publicLink" location="bottom">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiLinkVariant"
                  v-bind="templateProps"
                  color="primary"
                  variant="text"
                  density="comfortable"
                  target="_blank"
                  data-testid="public-link"
                  :href="publicLink"
                />
              </template>
              <span>Public Link</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <help-center-viewer :article="article" />
      </div>
      <v-skeleton-loader v-else type="article" />
    </v-col>
  </v-row>
</template>

<script setup>
import HelpCenterViewer from "@/components/help-center/HelpCenterViewer.vue";
import { getArticleById } from "@/api/help-desk.service";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed, onBeforeUnmount, ref } from "vue";
import { mdiPencil, mdiLinkVariant } from "@mdi/js";
import { useRouter } from "vue-router";

const props = defineProps({
  id: { type: [String, Number], required: true }
});

const router = useRouter();
const head = useHead();

const instance = useInstanceStore();
const user = useUserStore();
const snackbar = useSnackbarStore();
const { breadcrumb } = storeToRefs(instance);
onBeforeUnmount(() => (breadcrumb.value = ""));

const article = ref({});

const publicLink = computed(() => {
  if (article.value.minimumGroup) return null;
  return `${instance.helpCenterUrl}/a/${article.value.link}`;
});

const editRouterLink = computed(() => {
  if (!user.isGroupTwoPlus) return null;
  return {
    name: "HelpCenterArticleEditor",
    params: { id: props.id }
  };
});

async function getArticle() {
  try {
    article.value = await getArticleById(props.id);
    breadcrumb.value = article.value.name;
    head.patch({ title: article.value.name });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    await router.replace({ name: "Home" });
  }
}

getArticle();
</script>

<style scoped>
.article-container {
  margin-top: 2em;
  max-width: 800px;
  border-radius: 8px;
  padding: 1.5em;
}
</style>
