<template>
  <v-card>
    <v-card-title class="py-5">Quote & Apply Applications</v-card-title>
    <v-divider />
    <v-card-text>
      <v-col cols="12" align="center">
        <div v-if="chartOptions.series.length" class="highcharts-areaspline">
          <highcharts-graph
            :options="chartOptions"
            style="border-radius: 5px"
          />
        </div>
        <div v-else-if="!chartOptions.series.length && loaded">
          <v-alert color="info">No Quote & Apply Data to Report</v-alert>
        </div>
        <div v-else-if="loaded">
          <v-alert color="error">Unable to load Quote & Apply Reports</v-alert>
        </div>
        <v-progress-circular v-else indeterminate />
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import { getEAppsReport } from "@/api/reports.service";
import { defineAsyncComponent } from "vue";

export default {
  components: {
    HighchartsGraph: defineAsyncComponent(() =>
      import("@/components/shared/HighchartsGraph.vue")
    )
  },
  props: {
    all: Boolean
  },
  data() {
    return {
      chartOptions: {
        chart: {
          styledMode: true,
          type: "areaspline",
          style: {
            fontFamily: "Roboto"
          }
        },
        tooltip: {
          xDateFormat: "%B"
        },
        title: {
          text: "Quotes, Leads, and Completed eApps by Month for BackNine",
          style: {
            textTransform: "none"
          }
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            month: "%b %Y"
          }
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: "Number of eApps"
          }
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: false
            }
          }
        },
        series: [],
        defs: {
          gradient0: {
            tagName: "linearGradient",
            id: "gradient-0",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          },
          gradient1: {
            tagName: "linearGradient",
            id: "gradient-1",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          }
        }
      },
      loaded: false
    };
  },
  created() {
    this.getReportData();
  },
  methods: {
    async getReportData() {
      const params = new URLSearchParams();
      if (this.all) params.append("view", "all");
      try {
        this.chartOptions.series = await getEAppsReport(params);
      } catch (e) {
        // do nothing
      } finally {
        this.loaded = true;
      }
    }
  }
};
</script>
