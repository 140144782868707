<template>
  <v-card>
    <v-row class="ma-0 py-3 flex-nowrap" align="center">
      <div>
        <v-card-title> APS </v-card-title>
      </div>
      <v-spacer />
      <v-tabs
        class="mb-0 mx-3"
        right
        style="width: unset"
        color="primary"
        @update:model-value="updateData"
      >
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          class="text-none"
          :data-testid="`${tab.title}-tab`"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-divider />
    <v-card-text class="pt-7">
      <v-col cols="12" align="center">
        <div
          v-if="activeTab.loaded && activeTab.options.series"
          class="highcharts-areaspline"
          :data-testid="`${activeTab.title}-graph`"
        >
          <highcharts-graph
            :options="activeTab.options"
            style="border-radius: 5px"
          />
        </div>
        <div v-else-if="activeTab.loaded">
          <v-alert color="error">Unable to load Premium Reports</v-alert>
        </div>
        <v-progress-circular v-else indeterminate />
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getApsCompletionReport,
  getApsCreationReport,
  getApsFrequencyReport,
  getFirstApsReport
} from "@/api/reports.service";
import { defineAsyncComponent } from "vue";

const AREASPLINE = {
  styledMode: true,
  type: "areaspline",
  style: {
    fontFamily: "Roboto"
  }
};

const TITLE = text => ({
  text,
  style: {
    textTransform: "none"
  }
});

const X_AXIS = {
  type: "datetime",
  dateTimeLabelFormats: {
    month: "%b %Y"
  }
};

const AREASPLINE_DISBLE_MARKER_OPTIONS = {
  areaspline: {
    marker: {
      enabled: false
    }
  }
};

const GRADIENT_0 = {
  tagName: "linearGradient",
  id: "gradient-0",
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 1,
  children: [
    {
      tagName: "stop",
      offset: 0
    },
    {
      tagName: "stop",
      offset: 1
    }
  ]
};

const GRADIENT_1 = {
  tagName: "linearGradient",
  id: "gradient-1",
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 1,
  children: [
    {
      tagName: "stop",
      offset: 0
    },
    {
      tagName: "stop",
      offset: 1
    }
  ]
};

const Y_AXIS_DAYS = {
  allowDecimals: false,
  min: 0,
  title: {
    text: "Days"
  }
};

const Y_AXIS_NUMBER = {
  allowDecimals: false,
  min: 0,
  title: {
    text: "Number"
  }
};

export default {
  components: {
    HighchartsGraph: defineAsyncComponent(() =>
      import("@/components/shared/HighchartsGraph.vue")
    )
  },
  props: {
    all: Boolean
  },
  data() {
    return {
      activeTabIndex: 0,
      tabs: [
        {
          title: "Days to Create APS",
          loaded: false,
          loading: false,
          fetchFunc: () => this.genericFetch(0, getFirstApsReport),
          options: {
            chart: AREASPLINE,
            plotOptions: AREASPLINE_DISBLE_MARKER_OPTIONS,
            title: TITLE("Days Between Case and APS Creation by Month"),
            tooltip: {
              xDateFormat: "%B"
            },
            xAxis: X_AXIS,
            yAxis: Y_AXIS_DAYS,
            series: [],
            defs: {
              gradient0: GRADIENT_0,
              gradient1: GRADIENT_1
            }
          }
        },
        {
          title: "Completion Time",
          loaded: false,
          loading: false,
          fetchFunc: () => this.genericFetch(1, getApsCompletionReport),
          options: {
            chart: AREASPLINE,
            title: TITLE("Days from Creation to Completion by Month"),
            plotOptions: AREASPLINE_DISBLE_MARKER_OPTIONS,
            xAxis: X_AXIS,
            yAxis: Y_AXIS_DAYS,
            series: [],
            defs: {
              gradient0: GRADIENT_0,
              gradient1: GRADIENT_1
            }
          }
        },
        {
          title: "Creations",
          loaded: false,
          loading: false,
          fetchFunc: () => this.genericFetch(2, getApsCreationReport),
          options: {
            chart: AREASPLINE,
            title: TITLE("APS Creations by Month"),
            plotOptions: AREASPLINE_DISBLE_MARKER_OPTIONS,
            xAxis: X_AXIS,
            yAxis: Y_AXIS_DAYS,
            series: [],
            defs: {
              gradient0: GRADIENT_0,
              gradient1: GRADIENT_1
            }
          }
        },
        {
          title: "Frequency",
          loaded: false,
          loading: false,
          fetchFunc: () => this.genericFetch(3, getApsFrequencyReport),
          options: {
            chart: AREASPLINE,
            title: TITLE("APS Required Frequency"),
            plotOptions: AREASPLINE_DISBLE_MARKER_OPTIONS,
            xAxis: X_AXIS,
            yAxis: Y_AXIS_NUMBER,
            series: [],
            defs: {
              gradient0: GRADIENT_0,
              gradient1: GRADIENT_1
            }
          }
        }
      ]
    };
  },
  computed: {
    activeTab() {
      return this.tabs[this.activeTabIndex];
    }
  },
  methods: {
    updateData(index) {
      this.activeTabIndex = index;
      if (this.tabs[index].loaded) {
        return;
      }
      this.tabs[index].fetchFunc();
    },
    async genericFetch(index, func) {
      this.tabs[index].loading = true;
      try {
        const params = new URLSearchParams();
        if (this.all) params.append("view", "all");
        this.tabs[index].options.series = await func(params);
      } catch (e) {
        // Do nothing.
      } finally {
        this.tabs[index].loading = false;
        this.tabs[index].loaded = true;
      }
    }
  }
};
</script>
