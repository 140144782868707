<template>
  <v-form>
    <v-row dense>
      <v-col cols="12">
        <h3 class="text-h4 mb-3 font-weight-light">Invite an Assistant</h3>
        <p class="text-body-1">
          {{ assistantSubtext }}
        </p>
      </v-col>
      <v-col cols="12">
        <basic-email-input
          v-model="advisor.email"
          data-testid="assistant-email"
          autofocus
          required
          variant="outlined"
          dense
          label="E-Mail Address"
          :prepend-inner-icon="mdiEmail"
          :disabled="loading"
          :success="emailValidation.success"
          :error-messages="emailValidation.errorMessages"
          @valid="emailIsValid = $event"
        />
      </v-col>
      <v-col cols="12">
        <text-field
          v-model="advisor.firstName"
          data-testid="assistant-first-name"
          data-lpignore="true"
          variant="outlined"
          dense
          label="First Name"
          :prepend-inner-icon="mdiAccount"
          :disabled="loading"
          :success="firstNameValidation.success"
          :error-messages="firstNameValidation.errorMessages"
        />
      </v-col>
      <v-col cols="12">
        <text-field
          v-model="advisor.lastName"
          data-lpignore="true"
          data-testid="assistant-last-name"
          label="Last Name"
          variant="outlined"
          dense
          :prepend-inner-icon="mdiAccountMultiple"
          :disabled="loading"
          :success="lastNameValidation.success"
          :error-messages="lastNameValidation.errorMessages"
        />
      </v-col>

      <v-col v-if="!user.isGroupOne" cols="12">
        <advisor-search
          v-model="advisor.ownable"
          data-testid="assistant-advisor"
          label="Assisting which Advisor?"
          persistent-hint
          hint="Whose assistant is this?"
          :disabled="loading"
          :success="ownableValidation.success"
          :error-messages="ownableValidation.errorMessages"
          @update:model-value="fetchSignerInfo"
        />
      </v-col>

      <v-fade-transition mode="out-in">
        <v-col v-if="signerRelationships.length" cols="12">
          <v-card flat>
            <v-card-title class="pl-0">
              Give {{ loweredConnectionName }} access to these advisors
            </v-card-title>

            <v-chip-group v-model="additionalAccessTo" multiple>
              <v-chip
                v-for="{ key, name } in signerRelationships"
                :key="key"
                :value="key"
                :text="name"
                :color="additionalAccessTo.includes(key) ? 'primary' : null"
              />
            </v-chip-group>
          </v-card>
        </v-col>
      </v-fade-transition>

      <v-col v-if="advisorName" cols="12">
        <v-card flat>
          <v-card-title class="pl-0"> Access Settings </v-card-title>
          <v-card-subtitle class="pl-0">
            Define {{ loweredConnectionName }}'s access to
            {{ advisorName }}
          </v-card-subtitle>
          <v-card-text class="pa-0">
            <v-row dense>
              <v-fade-transition mode="out-in">
                <v-col v-if="advisorName" cols="12" class="pa-0">
                  <connection-settings
                    v-model:appointments="advisor.permissions.appointments"
                    v-model:appointments-emails="
                      advisor.permissions.appointmentsEmails
                    "
                    v-model:cases="advisor.permissions.cases"
                    v-model:cases-emails="advisor.permissions.casesEmails"
                    v-model:contract-parties="
                      advisor.permissions.contractParties
                    "
                    v-model:contract-parties-emails="
                      advisor.permissions.contractPartiesEmails
                    "
                    v-model:eapps="advisor.permissions.eapps"
                    v-model:eapps-emails="advisor.permissions.eappsEmails"
                    v-model:payments="advisor.permissions.payments"
                    v-model:payments-emails="advisor.permissions.paymentsEmails"
                    v-model:quick-quotes="advisor.permissions.quickQuotes"
                    v-model:quick-quotes-emails="
                      advisor.permissions.quickQuotesEmails
                    "
                    v-model:quotes="advisor.permissions.quotes"
                    v-model:quotes-emails="advisor.permissions.quotesEmails"
                    :advisor-name="advisorName"
                    :connection-name="connectionName"
                  />
                </v-col>
              </v-fade-transition>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="advisorName" cols="12" class="mt-5">
        <v-divider />
      </v-col>
      <v-col cols="12" class="mt-3">
        <p style="font-size: 16px; font-weight: bold">
          Subscribe to marketing emails?
        </p>
        <v-radio-group
          v-model="advisor.marketing"
          inline
          color="accent"
          data-testid="assistant-marketing"
          :disabled="loading"
          :success="marketingValidation.success"
          :error-messages="marketingValidation.errorMessages"
        >
          <v-radio :value="true" label="Yes" />
          <v-radio :value="false" label="No" />
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <app-button
          color="primary"
          class="text-none"
          data-testid="assistant-create"
          :loading="loading"
          @click="createAssistant"
        >
          <v-icon class="mr-1" :icon="mdiPlus" /> Create Assistant
        </app-button>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup>
import { getSignerRelationships } from "@/api/advisors.service";
import {
  computedValidation,
  listToSentence,
  parseErrorMessage
} from "@/util/helpers";
import ConnectionSettings from "@/components/shared/ConnectionSettings.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";

import { inviteAssistant } from "@/api/invites.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { useRouter } from "vue-router";
import { mdiEmail, mdiAccount, mdiAccountMultiple, mdiPlus } from "@mdi/js";

const snackbar = useSnackbarStore();
const user = useUserStore();
const router = useRouter();

const emailIsValid = ref(null);
const signerRelationships = ref([]);
const loading = ref(false);
const advisor = ref({
  firstName: null,
  lastName: null,
  email: null,
  additionalAccessTo: [],
  ownable: {},
  marketing: null,
  permissions: {
    appointments: true,
    appointmentsEmails: true,
    cases: true,
    casesEmails: true,
    contractParties: true,
    contractPartiesEmails: true,
    eapps: true,
    eappsEmails: true,
    payments: true,
    paymentsEmails: true,
    quickQuotes: true,
    quickQuotesEmails: true,
    quotes: true,
    quotesEmails: true
  }
});

const v$ = useVuelidate(
  {
    advisor: {
      email: {
        required: Boolean,
        isValid: () => emailIsValid.value === true
      },
      ownable: {
        required: val => user.isGroupOne || Boolean(val?.id)
      },
      firstName: {
        required: val => Boolean(val)
      },
      lastName: {
        required: val => Boolean(val)
      },
      marketing: {
        required: val => [true, false].includes(val)
      }
    }
  },
  { advisor },
  { $autoDirty: true, $scope: null }
);

const additionalAccessTo = computed({
  get() {
    return advisor.value.additionalAccessTo.map(({ key }) => key);
  },
  set(v) {
    if (!v) {
      advisor.value.additionalAccessTo.splice(
        0,
        advisor.value.additionalAccessTo
      );
      return;
    }

    const advisors = [];
    v.forEach(key => {
      const advisor = signerRelationships.value.find(({ key: k }) => key === k);
      advisors.push(advisor);
    });

    advisor.value.additionalAccessTo = advisors;
  }
});
const assistantSubtext = computed(() => {
  if (user.isGroupOne)
    return "Create an account that can access your eapps, Cases, Appointments, etc.";
  return "Create an account with access to an agent";
});
const advisorName = computed(() => {
  const access = [];
  if (user.isGroupOne) {
    access.push(user.loginable.name);
  } else {
    access.push(advisor.value.ownable.name);
  }
  access.push(...advisor.value.additionalAccessTo.map(v => v.name));
  return listToSentence(access);
});
const connectionName = computed(() => {
  return (
    [advisor.value.firstName, advisor.value.lastName]
      .filter(Boolean)
      .join(" ") || "The Assistant"
  );
});
const loweredConnectionName = computed(() => {
  if (connectionName.value === "The Assistant") return "the assistant";
  return connectionName.value;
});

const ownableValidation = computedValidation(v$.value.advisor.ownable, {
  required: "Required"
});
const emailValidation = computedValidation(v$.value.advisor.email, {
  required: "Required",
  isValid: "Invalid Email"
});
const firstNameValidation = computedValidation(v$.value.advisor.firstName, {
  required: "Required"
});
const lastNameValidation = computedValidation(v$.value.advisor.lastName, {
  required: "Required"
});
const marketingValidation = computedValidation(v$.value.advisor.marketing, {
  required: "Required"
});

async function fetchSignerInfo() {
  try {
    signerRelationships.value.splice(0, signerRelationships.value.length);
    advisor.value.additionalAccessTo.splice(
      0,
      advisor.value.additionalAccessTo.length
    );
    let relationships;
    if (user.isGroupOne) {
      relationships = await fetchSignerInfoGroupOne();
    } else {
      relationships = await fetchSignerInfoGroupTwoPlus();
    }

    const relationshipsWithKeys = relationships.map(
      ({ id, type, ...attrs }) => ({
        id,
        type,
        ...attrs,
        key: `${type}-${id}`
      })
    );

    signerRelationships.value.push(...relationshipsWithKeys);

    advisor.value.additionalAccessTo.push(...signerRelationships.value);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
async function fetchSignerInfoGroupOne() {
  return await getSignerRelationships();
}
async function fetchSignerInfoGroupTwoPlus() {
  const ownableId = advisor.value.ownable?.id;
  const ownableType = advisor.value.ownable?.type;
  if (!ownableId || !ownableType) return [];
  return await getSignerRelationships(ownableId, ownableType);
}
async function createAssistant() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    loading.value = true;

    const body = {
      email: advisor.value.email,
      subscribe_to_marketing_emails: advisor.value.marketing,
      first_name: undefined,
      last_name: undefined,
      marketing_manager_id: undefined,
      npn: undefined,
      assign_commissions: undefined,
      lock_commissions: undefined,
      type: undefined,
      permissions: undefined,
      access_to: undefined,
      ownable_id: undefined,
      ownable_type: undefined
    };

    body.first_name = advisor.value.firstName;
    body.last_name = advisor.value.lastName;

    const permissions = advisor.value.permissions;
    body.permissions = {
      appointments: permissions.appointments,
      appointments_emails: permissions.appointmentsEmails,
      cases: permissions.cases,
      cases_emails: permissions.casesEmails,
      contract_parties: permissions.contractParties,
      contract_parties_emails: permissions.contractPartiesEmails,
      electronic_applications: permissions.eapps,
      electronic_applications_emails: permissions.eappsEmails,
      payments: permissions.payments,
      payments_emails: permissions.paymentsEmails,
      quick_quotes: permissions.quickQuotes,
      quick_quotes_emails: permissions.quickQuotesEmails,
      quotes: permissions.quotes,
      quotes_emails: permissions.quotesEmails
    };

    body.access_to = [];

    const advisors = advisor.value.additionalAccessTo.map(({ id, type }) => ({
      id,
      type
    }));

    body.access_to.push(...advisors);

    if (!user.isGroupOne) {
      body.ownable_id = advisor.value.ownable.id;
      body.ownable_type = advisor.value.ownable.type;
      body.marketing_manager_id = advisor.value.ownable.marketing_manager_id;
    }

    if (user.isGroupOne) {
      body.access_to.push({
        id: user.loginable.id,
        type: user.loginable.type
      });
    } else {
      body.access_to.push({
        id: advisor.value.ownable.id,
        type: advisor.value.ownable.type
      });
    }

    const id = await inviteAssistant(body);
    router.push({
      name: "AgentView",
      params: {
        id
      }
    });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}

if (user.isGroupOne) fetchSignerInfo();

watch(
  () => advisor.value.assignment,
  val => {
    if (!val) advisor.value.lockCommissions = false;
  }
);
</script>
