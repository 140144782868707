import { setNoteFromRequest } from "@/factories/Note";

import {
  INDIVIDUAL_INDICATE,
  ENTITY_INDICATE
} from "@/factories/ContractPartyFactory";
import { Address, setRawFromAddress } from "@/factories/AddressFactory";
import {
  CONTINGENT_BENEFICIARY,
  INSURED,
  JOINT_INSURED,
  PRIMARY_BENEFICIARY
} from "@/factories/RoleFactory";

export function Party(model = {}) {
  return {
    addresses: {
      home: model?.addresses?.home,
      business: model?.addresses?.business
    },
    assets: model?.assets,
    avatarUrl: model?.avatarUrl,
    electronicApplicationId: model?.electronicApplicationId,
    email: model?.email,
    id: model?.id,
    income: model?.income,
    liabilities: model?.liabilities,
    name: model?.name,
    networth: model?.networth,
    notes: model?.notes,
    role: model?.role,
    type: model?.type,

    // Individual Details
    bankruptcyDischargeDate: model?.bankruptcyDischargeDate,
    bankruptcyType: model?.bankruptcyType,
    birthLocation: model?.birthLocation,
    birthdate: model?.birthdate,
    countryOfCitizenship: model?.countryOfCitizenship,
    driversLicense: model?.driversLicense,
    gender: model?.gender,
    lastBuild: model?.lastBuild,
    maritalStatus: model?.maritalStatus,
    occupation: {
      occupation: model?.occupation?.occupation,
      duration: model?.occupation?.duration,
      employer: model?.occupation?.employer
    },
    partyId: model?.partyId,
    primaryPhone: model?.primaryPhone,
    phoneMobile: model?.phoneMobile,
    usEntryDate: model?.usEntryDate,
    visaType: model?.visaType,

    // Entity Details
    bankruptcy: model?.bankruptcy,
    bankruptcyDetails: model?.bankruptcyDetails,
    formationDate: model?.formationDate,
    irrevocable: model?.irrevocable,
    phoneWork: model?.phoneWork,
    responsibleIndividual: model?.responsibleIndividual,
    taxType: model?.taxType,
    tin: model?.tin
  };
}

export function Individual(model = {}) {
  return {
    addresses: {
      home: model?.addresses?.home || null,
      business: model?.addresses?.business || null
    },
    assets: model?.assets || null,
    avatarUrl: model?.avatarUrl || null,
    bankruptcyDischargeDate: model?.bankruptcyDischargeDate || null,
    bankruptcyType: model?.bankruptcyType || null,
    birthLocation: model?.birthLocation || null,
    birthdate: model?.birthdate || null,
    countryOfCitizenship: model?.countryOfCitizenship || null,
    driversLicense: model?.driversLicense || null,
    electronicApplicationId: model?.electronicApplicationId || null,
    email: model?.email || null,
    gender: model?.gender || null,
    id: model?.id || null,
    income: model?.income || null,
    lastBuild: model?.lastBuild || null,
    liabilities: model?.liabilities || null,
    maritalStatus: model?.maritalStatus || null,
    name: model?.name || null,
    networth: model?.networth || null,
    notes: model?.notes || null,
    occupation: {
      occupation: model?.occupation?.occupation || null,
      duration: model?.occupation?.duration || null,
      employer: model?.occupation?.employer || null
    },
    partyId: model?.partyId || null,
    primaryPhone: model?.primaryPhone || null,
    phoneMobile: model?.phoneMobile || null,
    usEntryDate: model?.usEntryDate || null,
    visaType: model?.visaType || null,
    role: model?.role || null,
    type: "Individual"
  };
}

export function setIndividualFromRequest(raw = {}) {
  const individual = Individual();
  individual.addresses = {
    home: raw?.addresses?.home, // it's a string
    business: raw?.addresses?.business // it's a string
  };
  individual.assets = raw?.assets;
  individual.avatarUrl = raw?.avatar_url;
  individual.bankruptcyDischargeDate = raw?.bankruptcy_discharge_date;
  individual.bankruptcyType = raw?.bankruptcy_type;
  individual.birthLocation = raw?.birth_location;
  individual.birthdate = raw?.birthdate;
  individual.countryOfCitizenship = raw?.country_of_citizenship;

  if (raw?.drivers_license) {
    const dl = raw.drivers_license;
    individual.driversLicense = {
      expiration: dl.expiration,
      noDriversLicenseReason: dl.no_drivers_license_reason,
      number: dl.number,
      state: dl.state
    };
  }

  individual.electronicApplicationId = raw?.electronic_application_id;
  individual.email = raw?.email;
  individual.gender = raw?.gender;
  individual.id = raw?.id;
  individual.income = raw?.income;
  individual.lastBuild = raw?.last_build;
  individual.liabilities = raw?.liabilities;
  individual.maritalStatus = raw?.marital_status;
  individual.name = raw?.name;
  individual.networth = raw?.networth;
  individual.occupation = {
    duration: raw?.occupation?.duration,
    employer: raw?.occupation?.employer,
    occupation: raw?.occupation?.occupation
  };
  individual.partyId = raw?.party_id;
  individual.primaryPhone = raw?.primary_phone;
  individual.usEntryDate = raw?.us_entry_date;
  individual.visaType = raw?.visa_type;

  individual.notes = raw?.notes?.map(setNoteFromRequest);
  return individual;
}

export function Entity(model = {}) {
  return {
    addresses: {
      business: model?.addresses?.business || null
    },
    assets: model?.assets || null,
    avatarUrl: model?.avatarUrl || null,
    bankruptcy: model?.bankruptcy || null,
    bankruptcyDetails: model?.bankruptcyDetails || null,
    electronicApplicationId: model?.electronicApplicationId || null,
    email: model?.email || null,
    formationDate: model?.formationDate || null,
    id: model?.id || null,
    income: model?.income || null,
    irrevocable: model?.irrevocable || null,
    liabilities: model?.liabilities || null,
    name: model?.name || null,
    networth: model?.networth || null,
    notes: model?.notes || null,
    phoneWork: model?.phoneWork || null,
    responsibleIndividual: {
      id: model?.responsibleIndividual?.id || null,
      name: model?.responsibleIndividual?.name || null
    },
    taxType: model?.taxType || null,
    tin: model?.tin || null,
    type: "Entity",
    role: model?.role || null
  };
}

export function setEntityFromRequest(model = {}) {
  const entity = Entity();
  entity.addresses = {
    business: model?.addresses?.business // it's a string
  };
  entity.assets = model?.assets;
  entity.avatarUrl = model?.avatar_url;
  entity.bankruptcy = model?.bankruptcy;
  entity.bankruptcyDetails = model?.bankruptcy_details;
  entity.electronicApplicationId = model?.electronic_application_id;
  entity.email = model?.email;
  entity.formationDate = model?.formation_date;
  entity.id = model?.id;
  entity.income = model?.income;
  entity.irrevocable = model?.irrevocable;
  entity.liabilities = model?.liabilities;
  entity.name = model?.name;
  entity.networth = model?.networth;
  entity.phoneWork = model?.phone_work;
  entity.responsibleIndividual = {
    id: model?.responsible_individual?.id,
    name: model?.responsible_individual?.name
  };
  entity.taxType = model?.tax_type;
  entity.tin = model?.tin;

  entity.notes = model?.notes?.map(setNoteFromRequest);
  return entity;
}

export function NewCaseIndividual(model = {}) {
  return {
    homeAddress: Address({ addressType: "home" }),
    netWorth: model?.netWorth || null,
    occupation: model?.occupation || null,
    dlState: model?.dlState || null,
    dlNumber: model?.dlNumber || null,
    phoneMobile: model?.phoneMobile || null,
    income: model?.income || null,
    birthdate: model?.birthdate || null,
    ssn: model?.ssn || null,
    gender: model?.gender || null,
    email: model?.email || null,
    roles: model?.roles || [],
    beneficiaryAmount: model?.beneficiaryAmount || null,
    relationship: model?.relationship || null,
    firstName: model?.firstName || null,
    lastName: model?.lastName || null,
    middleName: model?.middleName || null
  };
}

export function NewCaseIndividualToCreateRequest(individual) {
  const address = Address(individual.homeAddress);
  return {
    addresses_attributes: [setRawFromAddress(address)],
    birthdate: individual.birthdate,
    drivers_license_attributes: {
      state: individual.dlState,
      number: individual.dlNumber
    },
    phone_mobile: individual.phoneMobile,
    email: individual.email,
    first_name: individual.firstName,
    income: parseInt(individual.income),
    last_name: individual.lastName,
    middle_name: individual.middleName,
    natural_non_natural_indicate: INDIVIDUAL_INDICATE,
    networth: parseInt(individual.netWorth),
    occupation: individual.occupation || " ",
    roles_attributes: individual.roles.map(role => {
      const attributes = {
        role
      };

      if ([PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(role)) {
        attributes.beneficiary_amount = +(
          individual.beneficiaryAmount / 100
        ).toFixed(2);
        attributes.beneficiary_qualifier = "Percent";
      }
      if (![INSURED, JOINT_INSURED].includes(role)) {
        attributes.relationship = individual.relationship;
      }

      return attributes;
    }),
    gender: individual.gender,
    party_id: individual.ssn
  };
}

export function NewCaseEntity(model = {}) {
  return {
    name: model?.name || null,
    tin: model?.tin || null,
    email: model?.email || null,
    date: model?.date || null,
    roles: model?.roles || [],
    beneficiaryAmount: model?.beneficiaryAmount || null,
    relationship: model?.relationship || null,
    irrevocable: model?.irrevocable || null
  };
}

export function NewCaseEntityToCreateRequest(entity) {
  return {
    birthdate: entity.date,
    email: entity.email,
    irrevocable: entity.irrevocable ? "1" : "0",
    name: entity.name,
    natural_non_natural_indicate: ENTITY_INDICATE,
    party_id: entity.tin,
    roles_attributes: entity.roles.map(role => {
      const attributes = {
        role
      };

      if ([PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(role)) {
        attributes.beneficiary_amount = +(
          entity.beneficiaryAmount / 100
        ).toFixed(2);
        attributes.beneficiary_qualifier = "Percent";
      }
      if (![INSURED, JOINT_INSURED].includes(role)) {
        attributes.relationship = entity.relationship;
      }

      return attributes;
    })
  };
}
