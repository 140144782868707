<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="case-parties-advisors-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
    :search="search"
  >
    <template #[`item.name`]="{ item }">
      <dual-link-button
        v-if="item.additional.advisorProfileLink?.name"
        :left-link="item.additional.advisorProfileLink"
        :left-icon="mdiAccountTie"
        :left-text="item.name"
        :left-tooltip="`View ${item.name}'s Profile`"
        :right-link="item.additional.advisorSettingsLink"
        :show-right-link="
          Boolean(
            user.isGroupThreePlus && item.additional.advisorSettingsLink?.name
          )
        "
        :right-icon="mdiCogs"
        :right-tooltip="`View ${item.name}'s Settings`"
      />
      <template v-else>
        {{ item.name }}
      </template>
    </template>
    <template #[`item.ssn`]="{ item }">
      <sensitive-info-toggle
        :type="item.additional.ssnOrTin"
        :fetch-func="() => fetchSensitiveInfo(item.additional)"
      />
    </template>
    <template #[`item.code`]="{ item }">
      <v-tooltip v-if="item.additional.appointmentRouterLink" location="top">
        <template #activator="{ props: templateProps }">
          <app-button
            :to="item.additional.appointmentRouterLink"
            v-bind="templateProps"
            color="primary"
            variant="flat"
            size="small"
            class="text-none px-2"
          >
            <v-icon size="small" class="mr-1" :icon="mdiCalendar" />
            <div class="truncate-200">
              {{ item.code }}
            </div>
          </app-button>
        </template>
        <span>View {{ item.code }} </span>
      </v-tooltip>
      <template v-else>
        {{ item.code }}
      </template>
    </template>
    <template #[`item.splitPercent`]="{ item }">
      {{ item.splitPercent }}%
    </template>
    <template #[`item.estimatedAgentFirstYearAndExcessCommission`]="{ item }">
      {{ currencyFormat(item.estimatedAgentFirstYearAndExcessCommission, 0) }}
    </template>
    <template #[`item.actions`]="{ item }">
      <div class="ma-0" style="width: 120px">
        <app-button
          :icon="mdiPencil"
          color="success"
          data-testid="edit"
          variant="icon"
          @click="editAdvisor(item.additional)"
        />
        <app-button
          :icon="mdiDelete"
          color="error"
          data-testid="delete"
          variant="icon"
          @click="deleteRow(item.additional)"
        />
      </div>
    </template>
  </v-data-table>
</template>

<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import DualLinkButton from "@/components/shared/DualLinkButton.vue";
import SensitiveInfoToggle from "@/components/shared/SensitiveInfoToggle.vue";
import EditAdvisorDialog from "@/components/cases/case-dialogs/EditAdvisorDialog.vue";

import TableHeader from "@/classes/data-table/TableHeader";

import { getAgentSsn } from "@/api/agents.service";
import { getAgencyTin } from "@/api/agencies.service";
import { destroyAppointmentCase } from "@/api/appointment-cases.service";
import { currencyFormat } from "@/util/helpers";

import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { toRef, watch, markRaw } from "vue";
import { storeToRefs } from "pinia";
import { useCaseViewStore } from "@/stores/case-view";
import {
  mdiAccountTie,
  mdiCogs,
  mdiCalendar,
  mdiPencil,
  mdiDelete
} from "@mdi/js";

const props = defineProps({
  search: {
    type: String,
    required: false,
    default: ""
  }
});

const search = toRef(props, "search");

const { appointmentCases } = storeToRefs(useCaseViewStore());

const user = useUserStore();
const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "advisorName",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Split %",
      value: "splitPercent",
      map: "splitPercent",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Code",
      value: "code",
      map: "appointmentCode",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Est. First Year + Excess",
      value: "estimatedAgentFirstYearAndExcessCommission",
      map: "estimatedAgentFirstYearAndExcessCommission",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "SSN",
      value: "ssn",
      map: "advisor.ssn"
    }),
    new TableHeader({
      text: "Commission Type",
      value: "carrierAgreementType",
      map: "carrierAgreementType",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Manager",
      value: "manager",
      map: "marketingManagerName",
      ...TableHeader.IS_FILTERABLE
    })
  ],
  items: appointmentCases.value
});

if (user.isGroupThreePlus) {
  table.headers.value.push(
    new TableHeader({
      text: "Actions",
      value: "actions",
      width: "104px"
    })
  );
}

function fetchSensitiveInfo(appointmentCase) {
  if (appointmentCase.advisorType === "Agency") {
    return getAgencyTin(appointmentCase.advisorId);
  }
  return getAgentSsn(appointmentCase.advisorId);
}

async function editAdvisor(appointmentCase) {
  const result = await dialog.showDialog({
    component: markRaw(EditAdvisorDialog),
    modelValue: appointmentCase
  });

  if (!result?.appointmentCase) return;
  const { appointmentCase: ac } = result;
  const index = appointmentCases.value.findIndex(r => {
    return ac.appointmentCaseId === r.appointmentCaseId;
  });
  if (index === -1) return;

  // Reactive set?
  appointmentCases.value[index] = ac;
  const tableIndex = table.items.value.findIndex(
    r => ac.appointmentCaseId === r.appointmentCaseId
  );
  if (tableIndex === -1) return;
  table.items.value[tableIndex] = ac;
}

async function deleteRow(appointmentCase) {
  const id = appointmentCase.appointmentCaseId;
  const res = await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Remove ${appointmentCase.advisorName} from this Case?`,
    subtitle: "This cannot be undone",
    func: () => destroyAppointmentCase(id)
  });

  if (!res?.confirm) return;

  const index = appointmentCases.value.findIndex(
    v => v.appointmentCaseId === id
  );
  if (index === -1) return;
  appointmentCases.value.splice(index, 1);
}

watch(appointmentCases, () => {
  table.items.value.splice(
    0,
    table.items.value.length,
    ...appointmentCases.value
  );
});
</script>
