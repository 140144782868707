<template>
  <card-items :items="items" />
</template>
<script setup>
import CardItems from "@/components/shared/card-items/CardItems.vue";

import {
  currencyFormat,
  getRouterLink,
  timestampFormatter
} from "@/util/helpers";
import { computed } from "vue";
import {
  linkItem,
  routerLinkItem,
  sensitiveInfoItem,
  textItem
} from "@/components/shared/card-items/card-items";
import { getSensitiveIndividualInformation } from "@/api/individual.service";
import { getSensitiveEntityInformation } from "@/api/entities.service";
import { toRef } from "vue";

const props = defineProps({
  party: {
    type: Object,
    required: true
  }
});

const party = toRef(props, "party");

const isIndividual = party.value.type === "Individual";
const isEntity = !isIndividual;

const individualItems = computed(() => {
  if (!isIndividual) return [];

  const items = [];

  if (party.value.name) {
    items.push(
      textItem({
        title: "Name",
        text: party.value.name
      })
    );
  }

  if (party.value.primaryPhone) {
    items.push(
      linkItem({
        title: "Primary Phone",
        text: party.value.primaryPhone,
        href: `tel:${party.value.primaryPhone}`,
        dataOutboundId: party.value.id,
        dataOutboundType: "Individual",
        dataOutboundNumber: party.value.primaryPhone
      })
    );
  }

  if (party.value.email) {
    items.push(
      linkItem({
        title: "Email",
        text: party.value.email,
        href: `mailto:${party.value.email}`
      })
    );
  }

  if (party.value.gender) {
    items.push(
      textItem({
        title: "Gender",
        text: party.value.gender
      })
    );
  }

  if (party.value.birthdate) {
    items.push(
      textItem({
        title: "Birthdate",
        text: timestampFormatter(party.value.birthdate, "sole-day")
      })
    );
  }

  if (party.value.partyId) {
    items.push(
      sensitiveInfoItem({
        title: "SSN",
        type: "ssn",
        fetchFunc: () => getSensitiveIndividualInformation(party.value.id)
      })
    );
  }

  if (party.value.addresses?.home) {
    items.push(
      textItem({
        title: "Home Address",
        text: party.value.addresses.home
      })
    );
  }

  if (party.value.addresses?.business) {
    items.push(
      textItem({
        title: "Business Address",
        text: party.value.addresses.business
      })
    );
  }

  if (party.value.income) {
    items.push(
      textItem({
        title: "Income",
        text: currencyFormat(party.value.income, 0)
      })
    );
  }

  if (party.value.assets) {
    items.push(
      textItem({
        title: "Assets",
        text: currencyFormat(party.value.assets, 0)
      })
    );
  }

  if (party.value.liabilities) {
    items.push(
      textItem({
        title: "Liabilities",
        text: currencyFormat(party.value.liabilities, 0)
      })
    );
  }

  if (party.value.occupation) {
    let occupationDuration = party.value.occupation.duration;
    if (![null, undefined].includes(occupationDuration)) {
      occupationDuration += occupationDuration === 1 ? " Year" : " Years";
    }

    const textValue = [
      party.value.occupation.occupation,
      party.value.occupation.employer,
      occupationDuration
    ]
      .filter(Boolean)
      .join(", ");

    if (textValue) {
      items.push(
        textItem({
          title: "Occupation",
          text: textValue
        })
      );
    }
  }

  if (party.value.visaType) {
    items.push(
      textItem({
        title: "Visa Type",
        text: party.value.visaType
      })
    );
  }

  if (party.value.usEntryDate) {
    items.push(
      textItem({
        title: "US Entry Date",
        text: timestampFormatter(party.value.usEntryDate, "sole-day")
      })
    );
  }

  const dlText = [];
  const dl = party.value.driversLicense;
  if (dl?.state) dlText.push(`State: ${dl.state}`);
  if (dl?.number) dlText.push(`Number: ${dl.number}`);
  if (dl?.expiration) {
    dlText.push(`Expiration: ${timestampFormatter(dl.expiration, "sole-day")}`);
  }
  if (dl?.noDriversLicenseReason) {
    dlText.push(`No Drivers License Reason: ${dl.noDriversLicenseReason}`);
  }
  if (dlText.length) {
    items.push(textItem({ title: "Drivers License", text: dlText.join("\n") }));
  }

  return items;
});

const entityItems = computed(() => {
  if (!isEntity) return [];

  const items = [];

  if (party.value.responsibleIndividual?.name) {
    items.push(
      routerLinkItem({
        title: "Signer Name",
        text: party.value.responsibleIndividual.name,
        to: getRouterLink("Individual", party.value.responsibleIndividual.id)
      })
    );
  }

  if (party.value.phoneWork) {
    items.push(
      linkItem({
        title: "Primary Phone",
        text: party.value.phoneWork,
        href: `tel:${party.value.phoneWork}`,
        dataOutboundId: party.value.id,
        dataOutboundType: "Entity",
        dataOutboundNumber: party.value.phoneWork
      })
    );
  }

  if (party.value.email) {
    items.push(
      linkItem({
        title: "Email",
        text: party.value.email,
        href: `mailto:${party.value.email}`
      })
    );
  }

  if (party.value.formationDate) {
    items.push(
      textItem({
        title: "Formation Date",
        text: timestampFormatter(party.value.formationDate, "sole-day")
      })
    );
  }

  if (party.value.tin) {
    items.push(
      sensitiveInfoItem({
        title: "TIN",
        type: "tin",
        fetchFunc: () => getSensitiveEntityInformation(party.value.id)
      })
    );
  }

  if (party.value.addresses?.business) {
    items.push({
      title: "Business Address",
      text: party.value.addresses.business
    });
  }

  return items;
});

const items = computed(() =>
  isIndividual ? individualItems.value : entityItems.value
);
</script>
