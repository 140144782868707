<template>
  <v-fade-transition mode="out-in">
    <v-row v-if="loading" key="loading" dense>
      <v-col cols="12" class="pb-0">
        <v-skeleton-loader type="card" />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-sheet color="section" class="py-3 px-0">
          <v-skeleton-loader type="card" />
        </v-sheet>
      </v-col>
    </v-row>
    <v-col v-else key="loaded" dense class="pa-1 mb-12">
      <v-card flat tile>
        <product-view-card />
        <template v-if="tabs.length === 1">
          <v-divider />
          <component :is="tabs[0].component" />
        </template>
        <template v-else>
          <v-tabs v-model="activeTab" show-arrows color="primary">
            <v-tab
              v-for="tab in tabs"
              :key="tab.page"
              class="text-none"
              :data-testid="`tab-${tab.title}`"
            >
              <v-icon class="pr-1" :icon="tab.icon" />
              {{ tab.title }}
            </v-tab>
          </v-tabs>
          <v-divider />
          <v-tabs-window v-model="activeTab" touchless>
            <v-tabs-window-item
              v-for="(tab, index) in tabs"
              :key="tab.page + 'component'"
              class="section"
            >
              <component
                :is="tab.component"
                :active="activeTab === index"
                v-bind="tab.props || {}"
              />
            </v-tabs-window-item>
          </v-tabs-window>
        </template>
      </v-card>
    </v-col>
  </v-fade-transition>
</template>

<script setup>
import ProductViewCard from "@/components/products/ProductViewCard.vue";
import ProductViewCommissionDetails from "@/components/products/ProductViewCommissionDetails.vue";
import ApplicationVersionsTable from "@/components/application-versions/ApplicationVersionsTable.vue";
import ProductViewDetails from "@/components/products/ProductViewDetails.vue";
import ProductViewQuoteAndApply from "@/components/products/ProductViewQuoteAndApply.vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useProductView } from "@/stores/product-view";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed, onBeforeUnmount, ref, markRaw, watch } from "vue";
import {
  mdiAbTesting,
  mdiFileDocumentEdit,
  mdiGoogleCirclesExtended,
  mdiRocketLaunch
} from "@mdi/js";
import { useRouter } from "vue-router";

const props = defineProps({
  id: { type: [String, Number], required: true },
  page: {
    type: String,
    default: "details",
    required: false
  }
});

const head = useHead({ title: "Product View" });
const router = useRouter();
const instance = useInstanceStore();
const user = useUserStore();
const productView = useProductView();
const snackbar = useSnackbarStore();

const { isGroupFour, isGroupTwoPlus } = storeToRefs(user);
const { breadcrumb } = storeToRefs(instance);
const { name, id } = storeToRefs(productView);

const loading = ref(false);
const activeTab = ref(0);

onBeforeUnmount(() => (breadcrumb.value = ""));

const tabs = computed(() => {
  const tabs = [];

  tabs.push({
    icon: mdiGoogleCirclesExtended,
    title: "Commissions",
    component: markRaw(ProductViewCommissionDetails),
    page: "commissions"
  });

  if (isGroupTwoPlus.value) {
    tabs.push({
      icon: mdiFileDocumentEdit,
      title: "Details",
      component: markRaw(ProductViewDetails),
      page: "details"
    });
  }

  if (isGroupFour.value) {
    tabs.push(
      {
        icon: mdiAbTesting,
        title: "Versions",
        component: markRaw(ApplicationVersionsTable),
        page: "versions",
        props: {
          title: "Product Changes",
          changesToId: id.value,
          changesToType: "Product",
          color: "section",
          class: "bg-section"
        }
      },
      {
        icon: mdiRocketLaunch,
        title: "Quote & Apply",
        component: markRaw(ProductViewQuoteAndApply),
        page: "quote-and-apply"
      }
    );
  }

  return tabs;
});

async function getProduct() {
  try {
    loading.value = true;
    await productView.loadProduct(props.id);
    breadcrumb.value = name.value;
    head.patch({ title: name.value });
    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;
    else activeTab.value = tabs.value.findIndex(tab => tab.defaultTab);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    await router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

function syncQueryToTab() {
  if (!router) return;
  const tab = tabs.value[activeTab.value];
  if (!tab) return;
  let page = tab.page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

getProduct();
</script>
