<template>
  <v-card class="ma-1">
    <v-card-title>
      <v-row class="pl-6 pt-6 pb-2" align="start">
        Help Center Article Creator
        <v-spacer />
        <v-tooltip location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              v-bind="templateProps"
              variant="text"
              class="default-btn"
              density="comfortable"
              :icon="fullscreen ? mdiFullscreenExit : mdiFullscreen"
              @click="toggleFullscreen"
            />
          </template>
          <span v-if="fullscreen"> Exit Fullscreen</span>
          <span v-else>Enter Fullscreen</span>
        </v-tooltip>
        <v-tooltip v-if="article.id" location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              v-bind="templateProps"
              :icon="mdiDelete"
              variant="text"
              density="comfortable"
              color="error"
              data-testid="delete-article"
              @click="destroyArticle"
            />
          </template>
          <span>Delete Article</span>
        </v-tooltip>
        <v-tooltip
          v-if="articleIsPublic && article.minimumGroup === null"
          location="bottom"
        >
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiLinkVariant"
              v-bind="templateProps"
              color="primary"
              target="_blank"
              variant="text"
              density="comfortable"
              data-testid="public-link"
              :href="publicLink"
            />
          </template>
          <span>View Public Article</span>
        </v-tooltip>
      </v-row>
    </v-card-title>
    <v-card-subtitle class="pl-7"
      >Create an article in minutes!</v-card-subtitle
    >
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="6">
          <v-card tile color="section" flat>
            <v-card-title> Editor </v-card-title>
            <v-card-subtitle> Input your markdown here. </v-card-subtitle>
            <v-col cols="12">
              <text-field
                v-model="article.name"
                data-lpignore="true"
                label="Article Title"
                data-testid="article-title"
                counter="255"
                :prepend-inner-icon="mdiFormatTitle"
                :success="titleValidation.success"
                :error-messages="titleValidation.errorMessages"
                :loading="loadingArticle"
              />
            </v-col>
            <hr />
            <v-toolbar dense color="secondary" flat>
              <v-toolbar-items>
                <app-button
                  :icon="mdiFormatHeader1"
                  data-testid="header"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="insertFormat('header')"
                />
                <app-button
                  :icon="mdiFormatHeader2"
                  data-testid="header2"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="insertFormat('header2')"
                />
                <app-button
                  :icon="mdiFormatHeader3"
                  data-testid="header3"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="insertFormat('header3')"
                />
                <app-button
                  :icon="mdiFormatBold"
                  data-testid="bold"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="insertFormat('bold')"
                />
                <app-button
                  :icon="mdiFormatItalic"
                  data-testid="italic"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="insertFormat('italic')"
                />
                <app-button
                  :icon="mdiFormatParagraphSpacing"
                  data-testid="line-break"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="insertFormat('lineBreak')"
                />
                <app-button
                  :icon="mdiLink"
                  data-testid="link"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="linkDialog('link')"
                />
                <app-button
                  :icon="mdiImage"
                  data-testid="image"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="linkDialog('image')"
                />
                <app-button
                  :icon="mdiFileDocument"
                  data-testid="document"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="linkDialog('document')"
                />
                <app-button
                  :icon="mdiFormatListBulleted"
                  data-testid="unordered-list"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="insertFormat('unorderedlist')"
                />
                <app-button
                  :icon="mdiFormatListNumbered"
                  data-testid="ordered-list"
                  variant="text"
                  class="default-btn"
                  :disabled="loadingArticle"
                  @click="insertFormat('orderedlist')"
                />
                <v-menu>
                  <template #activator="{ props: templateProps }">
                    <app-button
                      v-bind="templateProps"
                      variant="text"
                      class="default-btn"
                      :icon="mdiVideoWirelessOutline"
                      data-testid="video"
                      :disabled="loadingArticle"
                    />
                  </template>
                  <v-list>
                    <v-list-item
                      data-testid="youtube"
                      @click="insertFormat('youtube')"
                    >
                      <v-list-item-title>YouTube </v-list-item-title>
                      <v-list-item-subtitle>
                        Can be any YouTube url, confirm on the right
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item
                      data-testid="vimeo"
                      @click="insertFormat('vimeo')"
                    >
                      <v-list-item-title>Vimeo </v-list-item-title>
                      <v-list-item-subtitle>
                        Does not work in all circumstances e.g.
                        https://vimeo.com/690688918 works
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="pb-0">
              <textarea-field
                ref="articleContent"
                v-model="article.content"
                placeholder="Content"
                rows="12"
                auto-grow
                data-testid="article-content"
                :success="contentValidation.success"
                :error-messages="contentValidation.errorMessages"
                :loading="loadingArticle"
              />
              <v-combobox
                v-model="article.tags"
                label="Tags"
                variant="outlined"
                density="compact"
                chips
                small-chips
                deletable-chips
                multiple
                data-testid="article-tags"
                no-data-text="Press 'Enter' to add tag"
                :prepend-inner-icon="mdiLabel"
                :items="articleTags"
                :success="hasTags"
              >
                <template #no-data>
                  <v-list-item>
                    Press "Tab" or click out of this box to add a tag
                  </v-list-item>
                </template>
              </v-combobox>
              <select-field
                v-model="article.minimumGroup"
                label="Visible By"
                data-testid="article-visibility"
                hide-details
                success
                :items="visibilityOptions"
              />
            </v-card-text>
            <v-card-actions class="px-4 pb-3">
              <v-spacer />
              <checkbox-field
                v-model="article.published"
                label="Publish Article"
                data-testid="article-published"
                :disabled="loadingArticle || creatingArticle"
              />
              <app-button
                class="text-none ml-4"
                color="primary"
                data-testid="article-save"
                :disabled="loadingArticle"
                :loading="creatingArticle"
                @click="saveArticle"
              >
                <v-icon class="mr-1" :icon="mdiContentSave" /> Save
              </app-button>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <div class="bg-section h-100 pa-3">
            <h2 class="text-h5">Preview</h2>
            <p class="text-subtitle-1">
              A preview of your content will appear here. Actual style may vary.
            </p>
            <v-divider class="mx-n3 my-3" />
            <help-center-viewer :article="article" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CreatorImageDialog from "@/components/help-center/HelpCenterCreatorImageDialog.vue";
import HelpCenterViewer from "@/components/help-center/HelpCenterViewer.vue";
import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { storeToRefs } from "pinia";
import {
  deleteArticle,
  getArticleById,
  updateArticle,
  uploadArticleImage
} from "@/api/help-desk.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
import {
  mdiFullscreenExit,
  mdiFullscreen,
  mdiDelete,
  mdiLinkVariant,
  mdiFormatTitle,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatBold,
  mdiFormatItalic,
  mdiLink,
  mdiImage,
  mdiFileDocument,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiVideoWirelessOutline,
  mdiLabel,
  mdiContentSave,
  mdiFormatParagraphSpacing
} from "@mdi/js";
import { useRouter } from "vue-router";
import { computed, markRaw, nextTick, onBeforeUnmount, ref } from "vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import useVuelidate from "@vuelidate/core";

const DEFAULT_TAGS = ["help", "blog", "press-release"];
const props = defineProps({
  id: { type: [String, Number], required: true }
});

const router = useRouter();
const instance = useInstanceStore();
const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const { breadcrumb, fullscreen, helpCenterUrl } = storeToRefs(instance);

const head = useHead({ title: "Article Editor" });

const article = ref({
  tags: [],
  name: "",
  content: "",
  published: false,
  id: null,
  minimumGroup: null
});

const v$ = useVuelidate(
  {
    article: {
      name: {
        required: v => someTextValidator(true, v, 2),
        length: val => val.length <= 255 && val.length > 0
      },
      content: { required: v => someTextValidator(true, v, 2) }
    }
  },
  {
    article
  },
  { $autoDirty: true, $scope: null }
);

const creatingArticle = ref(false);
const loadingArticle = ref(false);
const articleIsPublic = ref(false);
const articleContent = ref(null); // templateref

onBeforeUnmount(() => {
  fullscreen.value = false;
  breadcrumb.value = "";
});

const visibilityOptions = computed(() => {
  return [
    { title: "Public, Visible to the World", value: null },
    { title: "BOSS Users, Requires Sign-In", value: 1 },
    { title: "All BackNine Staff", value: 2 },
    { title: "Upper Level BackNine Staff", value: 3 },
    { title: "Admin Only", value: 4 }
  ].filter(({ value }) => value <= user.highest_group);
});
const hasTags = computed(() => Boolean(article.value.tags.length));

const articleTags = computed(() =>
  [
    ...new Set([
      ...DEFAULT_TAGS,
      ...article.value.tags.map(val => val.toLowerCase())
    ])
  ].filter(Boolean)
);

const titleValidation = computedValidation(v$.value.article.name, {
  required: "Required"
});
const contentValidation = computedValidation(v$.value.article.content, {
  required: "Required"
});
const publicLink = computed(() => {
  if (!article.value.link) return null;
  return `${helpCenterUrl.value}/a/${article.value.link}`;
});

function toggleFullscreen() {
  fullscreen.value = !fullscreen.value;
}

async function fetchArticle(id) {
  loadingArticle.value = true;
  try {
    const res = await getArticleById(id);
    article.value = res;
    breadcrumb.value = article.value.name;

    head.patch({ title: article.value.name });
    articleIsPublic.value = Boolean(article.value.published);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    await router.replace({ name: "Home" });
  } finally {
    loadingArticle.value = false;
  }
}

async function destroyArticle() {
  const res = await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Are you sure you want to delete ${article.value.name}?`,
    subtitle: "This will delete the public document too.",
    func: () => deleteArticle(article.value.id)
  });

  if (!res.delete) return;
  snackbar.showSuccessSnackbar({
    message: `Successfully Deleted ${article.value.name}`
  });
  router.push({ name: "HelpCenter" });
}

function getTextarea() {
  return articleContent.value.$el.querySelector("textarea");
}

async function linkDialog(type) {
  const locationStart =
    getTextarea().selectionStart ||
    Math.max(article.value.content.length - 1, 0);

  const res = await dialog.showDialog({
    component: markRaw(CreatorImageDialog),
    type,
    articleImageUploadFunc: file => uploadArticleImage(article.value.id, file)
  });
  if (!res.markdown) return;
  const textBefore = article.value.content.slice(0, locationStart);
  const textAfter = article.value.content.slice(locationStart);
  article.value.content = textBefore + res.markdown + textAfter;
  await nextTick();
  getTextarea().focus();
}
function insertFormat(type) {
  const locationStart =
    getTextarea().selectionStart ||
    Math.max(article.value.content.length - 1, 0);
  let character = "";
  if (type === "lineBreak") {
    character = "  \n   \n";
  } else if (type === "header") {
    character = locationStart === 0 ? "# " : "\n# ";
  } else if (type === "header2") {
    character = locationStart === 0 ? "## " : "\n## ";
  } else if (type === "header3") {
    character = locationStart === 0 ? "### " : "\n### ";
  } else if (type === "bold") {
    character = " **bold text** ";
  } else if (type === "italic") {
    character = " *italicized text* ";
  } else if (type === "link") {
    character = " [title](https://www.example.com) ";
  } else if (type === "image") {
    character =
      locationStart === 0
        ? "![alt text](image.jpg)"
        : "\n![alt text](image.jpg)";
  } else if (type === "orderedlist") {
    character = locationStart === 0 ? "1. " : "\n1. ";
  } else if (type === "unorderedlist") {
    character = locationStart === 0 ? "- " : "\n- ";
  } else if (type === "youtube") {
    character = " @[youtube](insert-youtube-link)";
  } else if (type === "vimeo") {
    character = " @[vimeo](insert-vimeo-link)";
  }

  const textBefore = article.value.content.slice(0, locationStart);
  const textAfter = article.value.content.slice(locationStart);
  article.value.content = textBefore + character + textAfter;
  nextTick(() => {
    getTextarea().focus();
  });
}

async function saveArticle() {
  const isValid = await v$.value.$validate();

  if (creatingArticle.value || !isValid) return;
  creatingArticle.value = true;

  try {
    await updateArticle(article.value.id, {
      name: article.value.name,
      content: article.value.content,
      published: Boolean(article.value.published),
      tags: article.value.tags,
      minimum_group: article.value.minimumGroup
    });

    snackbar.showSuccessSnackbar({
      message: `Successfully updated ${article.value.name}`
    });
    articleIsPublic.value = Boolean(article.value.published);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creatingArticle.value = false;
  }
}

fetchArticle(props.id);
</script>
