<template>
  <v-card flat tile>
    <v-row class="ma-0" align="center">
      <v-col>
        <strong style="font-size: 1.2rem">
          {{ name }}
        </strong>
        <br />
        {{ carrier.name }}
      </v-col>
      <v-col>
        <v-row justify="end" class="ma-0">
          <carrier-image :id="carrier.id" :image="carrier.avatarUrl" />
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <card-items :items="cardDetails" />
    <template v-if="availableGuides.length">
      <v-divider />
      <v-row class="ma-0" align="center">
        <v-col
          v-for="(doc, index) in availableGuides"
          :key="index"
          :data-testid="`card-${doc.category}`"
          xl="2"
          lg="3"
          md="4"
          sm="6"
          cols="12"
        >
          <a v-bind="downloadDoc(doc)">
            <v-icon color="primary" size="small" :icon="mdiFileDocument" />
            {{ doc.category }}
          </a>
        </v-col>
      </v-row>
      <v-divider />
    </template>
    <v-row
      v-if="trainingInstructions"
      class="ma-0"
      data-testid="training-instructions"
    >
      <v-col cols="12">
        <v-alert type="info" class="mb-0" variant="outlined">
          <strong>Product Training Required!</strong> Training Instructions:
          {{ trainingInstructions }}
        </v-alert>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import CardItems from "@/components/shared/card-items/CardItems.vue";

import CarrierImage from "@/components/shared/CarrierImage.vue";
import { downloadFileAsLink } from "@/util/helpers";
import { getDocumentUrl } from "@/api/documents.service";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useProductView } from "@/stores/product-view";
import { textItem } from "@/components/shared/card-items/card-items";
import { mdiFileDocument } from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";

const { mdAndDown } = useDisplay();
const { isGroupTwoPlus, isGroupFour } = storeToRefs(useUserStore());
const {
  firstYearCommission,
  schedules,
  totalFirst,
  totalRenewal,
  chargeback,
  linePercentage,
  consumerGuide,
  underwritingGuide,
  advisorGuide,
  name,
  carrier,
  trainingInstructions,
  freeWithdrawals
} = storeToRefs(useProductView());

const cardDetails = computed(() => {
  const details = [];
  details.push(
    textItem({
      title: "First Year",
      text: `${firstYearCommission.value}%`
    })
  );
  if (schedules.value.bronze) {
    details.push(
      textItem({
        title: "Bronze First Year",
        text: `${schedules.value.bronze}%`
      })
    );
  }
  if (schedules.value.silver) {
    details.push(
      textItem({
        title: "Silver First Year",
        text: `${schedules.value.silver}%`
      })
    );
  }
  if (schedules.value.gold) {
    details.push(
      textItem({
        title: "Gold First Year",
        text: `${schedules.value.gold}%`
      })
    );
  }
  if (schedules.value.platinum) {
    details.push(
      textItem({
        title: "Platinum First Year",
        text: `${schedules.value.platinum}%`
      })
    );
  }
  if (schedules.value.diamond) {
    details.push(
      textItem({
        title: "Diamond First Year",
        text: `${schedules.value.diamond}%`
      })
    );
  }
  if (schedules.value.onyx) {
    details.push(
      textItem({
        title: "Onyx First Year",
        text: `${schedules.value.onyx}%`
      })
    );
  }
  if (totalFirst.value && isGroupTwoPlus.value) {
    details.push(
      textItem({
        title: "Total First Year",
        text: `${totalFirst.value}%`
      })
    );
  }
  if (totalRenewal.value && isGroupTwoPlus.value) {
    details.push(
      textItem({
        title: "Total Renewal",
        text: `${totalRenewal.value}%`
      })
    );
  }
  if (chargeback.value && isGroupFour.value) {
    details.push(
      textItem({
        title: "Chargeback",
        text: chargeback.value
      })
    );
  }
  if (linePercentage.value && isGroupFour.value) {
    details.push(
      textItem({
        title: "Line Percentage",
        text: `${linePercentage.value}%`
      })
    );
  }

  if (freeWithdrawals.value) {
    details.push(
      textItem({
        title: "Free Withdrawals",
        text: freeWithdrawals.value
      })
    );
  }

  return details;
});

const availableGuides = computed(() => {
  const availableGuides = [];
  if (consumerGuide.value) {
    availableGuides.push(consumerGuide.value);
  }
  if (underwritingGuide.value) {
    availableGuides.push(underwritingGuide.value);
  }
  if (advisorGuide.value) {
    availableGuides.push(advisorGuide.value);
  }
  return availableGuides;
});

function downloadDoc({ uid }) {
  return downloadFileAsLink(getDocumentUrl(uid), mdAndDown.value);
}
</script>
