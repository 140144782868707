<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="appointments-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :sort-by="table.options.value.sortBy"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row class="ma-0 pa-3" align="center">
        <h1 class="text-h5">Appointments</h1>
        <v-row class="ma-0" justify="end" align="center">
          <table-stats :stats="stats" />
          <v-tooltip location="top">
            <template #activator="{ props }">
              <app-button
                data-testid="appointments-table-email"
                :icon="mdiFileExport"
                variant="text"
                class="table-btn"
                density="comfortable"
                v-bind="props"
                @click="confirmAndEmailData"
              />
            </template>
            <span>Email Data</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template #activator="{ props }">
              <app-button
                data-testid="appointments-table-refresh-data"
                :icon="mdiRefresh"
                variant="text"
                density="comfortable"
                v-bind="props"
                @click="getData"
              />
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </v-row>
      </v-row>
      <v-divider />
      <table-filter
        :header-props="{
          class: 'mt-0 pa-3',
          rounded: false
        }"
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
      <v-divider />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.name`]="{ item }">
      <div class="truncate">
        <router-link
          class="text-none"
          :to="{
            name: 'AppointmentView',
            params: { id: item.additional.id }
          }"
        >
          {{ item.name }}
        </router-link>
      </div>
    </template>

    <template #[`item.linesOfAuthority`]="{ item }">
      {{ item.linesOfAuthority?.join(", ") }}
    </template>

    <template #[`item.created`]="{ item }">
      <timestamp-formatter :model-value="item.created" />
    </template>

    <template #[`item.followUp`]="{ item }">
      <timestamp-formatter :model-value="item.followUp" parser="sole-day" />
    </template>

    <template #[`item.lastNote`]="{ item }">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <div class="truncate" v-bind="props">
            {{ item.lastNote }}
          </div>
        </template>
        <span> {{ item.lastNote }} </span>
      </v-tooltip>
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableStats from "@/components/shared/data-table/TableStats.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import {
  lineFilterOptions,
  pendingAppointmentStatuses,
  inactiveAppointmentStatuses,
  activeAppointmentStatuses
} from "@/data/filter-options";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { parseErrorMessage } from "@/util/helpers";
import { getAppointments, emailAppointments } from "@/api/appointment.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { computed, markRaw } from "vue";
import { mdiCounter, mdiFileExport, mdiRefresh } from "@mdi/js";

const GENERAL_APPOINTMENT_STATUES = [
  { title: "Entered", value: "entered" },
  { title: "Pending", value: "pending" },
  { title: "Active", value: "active" },
  { title: "Inactive", value: "inactive" }
];
useHead({ title: "Appointments" });

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const { appointmentsTable } = storeToRefs(useTableStore());

const table = useTable({
  headers: getTableHeaders(),
  getData: getAppointments,
  emailData: emailAppointments,
  shouldIncludeCancelToken: true
});

if (user.isGroupTwoPlus) {
  table.filter.value.view = "my";
}
if (appointmentsTable.value.filter) {
  Object.keys(appointmentsTable.value.filter).forEach(key => {
    if (!table.headers.value.find(h => h.value === key)) return;
    table.filter.value[key] = appointmentsTable.value.filter[key];
  });
}

if (Object.keys(appointmentsTable.value.options).length) {
  table.options.value = appointmentsTable.value.options;
} else {
  table.options.value = TableOptions({
    sortBy: [{ key: "followUp", order: "desc" }]
  });
}

const stats = computed(() => {
  const score = {
    text: "Score",
    icon: mdiCounter,
    type: "loading",
    dataTestid: "stats-score"
  };

  if ("score" in table.stats.value) {
    score.type = "percent";
    score.value = table.stats.value.score;
  }

  return [score];
});

function getTableHeaders() {
  const VIEW_OPTIONS = user.isGroupTwoPlus
    ? [
        { title: "All Appointments", value: "all" },
        { title: "GA Appointments", value: "ga" },
        { title: "My Appointments", value: "my" }
      ]
    : [];
  const headers = [
    new TableHeader({
      text: "Code",
      value: "name",
      map: "name",
      sortFilterMap: "appointments.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrierName",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      sortKey: "carrier",
      filterOrder: 4,
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      sortFilterMap: "general_status",
      filterOrder: 2,
      selectableOptions: GENERAL_APPOINTMENT_STATUES,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Advisor",
      value: "advisor",
      map: "ownableName",
      sortable: false,
      sortFilterMap: [
        { key: "advisor_id", value: "id" },
        { key: "advisor_type", value: "type" }
      ],
      filterOrder: 3,
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Commission Type",
      value: "carrierAgreementType",
      map: "carrierAgreementType"
    }),
    new TableHeader({
      text: "Line",
      value: "linesOfAuthority",
      map: "linesOfAuthority",
      selectableOptions: lineFilterOptions,
      sortFilterMap: "lines_of_authority",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Created",
      value: "created",
      map: "createdAt",
      sortFilterMap: "appointments.created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Follow Up",
      value: "followUp",
      map: "followUpDate",
      sortFilterMap: "follow_up_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Last Note",
      value: "lastNote",
      map: "lastNote"
    }),
    new TableHeader({
      text: "Shared With",
      value: "additionalAdvisor",
      map: "additional_advisor",
      sortFilterMap: [
        { key: "shared_with_id", value: "id" },
        { key: "shared_with_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Specific Status",
      value: "specificStatus",
      map: "specificStatus",
      selectableOptionsFn: specificStatusFunc,
      sortFilterMap: "specific_status",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    })
  ];

  if (user.isGroupTwoPlus) {
    headers.unshift(
      new TableHeader({
        text: "View",
        value: "view",
        map: "view",
        selectableOptions: VIEW_OPTIONS,
        sortFilterMap: "view",
        filterOrder: 1,
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_MANDATORY
      })
    );
    headers.push(
      new TableHeader({
        text: "Comp Builder",
        value: "compBuilderName",
        map: "compBuilderName",
        sortFilterMap: "comp_builder_name",
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_STRING_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_ADDITIONAL
      })
    );
  }
  return headers;
}

function specificStatusFunc(filters) {
  const dict = {
    entered: [],
    active: activeAppointmentStatuses,
    pending: pendingAppointmentStatuses,
    inactive: inactiveAppointmentStatuses
  };

  return dict[filters.status];
}
function confirmAndEmailData() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Email Data?",
    subtitle: "Please confirm you would like this data emailed to you.",
    func: emailData
  });
}
async function emailData() {
  try {
    const additionalFilter = {};
    if (!user.isGroupTwoPlus) additionalFilter.view = "my";
    table.emailData(additionalFilter);
    snackbar.showSuccessSnackbar({
      message: `You will receive a copy of this data in your inbox shortly.`,
      timeout: 6000
    });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  }
}

async function getData() {
  const additionalFilter = {};
  if (!user.isGroupTwoPlus) additionalFilter.view = "my";
  try {
    await table.getData(additionalFilter);
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  appointmentsTable.value.options = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  appointmentsTable.value.filter = filter;
  table.resetPage();
  getData();
}
</script>
