-
<template>
  <div>
    <v-list>
      <template v-for="item in sidebarData" :key="item.title">
        <v-list-group
          v-if="item.subItems"
          v-bind="item.props"
          active-color="primary"
          data-testid="sidebar-group"
        >
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              class="mb-0 rounded ma-1 py-4"
              :title="item.title"
              :prepend-icon="item.icon"
            />
          </template>
          <v-list-item
            v-for="subItem in item.subItems"
            :key="subItem.title"
            data-testid="sidebar-item"
            v-bind="subItem.props"
            :title="subItem.title"
          />
        </v-list-group>
        <v-list-item
          v-else
          :color="item.color || 'primary'"
          class="mb-0 rounded mx-1 py-4"
          data-testid="sidebar-item"
          v-bind="item.props"
        >
          <template #prepend>
            <v-icon
              :color="item.color || null"
              :class="item.color ? 'opacity-100' : null"
              :icon="item.icon"
            />
          </template>
          <v-list-item-title :class="item.titleClass || ''">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script setup>
import isHoliday from "@/util/is-holiday";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";
import { useDialogStore } from "@/stores/dialog";
import { PERMISSION } from "@/constants/permissions.constants";
import {
  mdiAccountSupervisor,
  mdiBriefcaseVariant,
  mdiBullhorn,
  mdiCalculator,
  mdiCash,
  mdiCubeOutline,
  mdiCurrencyUsd,
  mdiFileChart,
  mdiFileDocumentEdit,
  mdiFileDocumentOutline,
  mdiGolf,
  mdiHome,
  mdiLifebuoy,
  mdiRocketLaunch,
  mdiTable
} from "@mdi/js";
import { markRaw } from "vue";
import CreateQuoteAndApplySite from "@/dialogs/CreateQuoteAndApplySite.vue";

const user = useUserStore();
const dialog = useDialogStore();

const emailMarketing = computed(() => {
  if (user.loginable.approved_domain_url) {
    return {
      icon: mdiBullhorn,
      props: { link: true, to: { name: "MarketingEmails" } },
      title: "Marketing"
    };
  }

  return {
    icon: mdiBullhorn,
    props: {
      link: true,
      onClick: () => {
        dialog.showDialog({
          component: markRaw(CreateQuoteAndApplySite),
          scrollable: true,
          title: "Marketing requires a Quote & Apply Website",
          subtitle: ""
        });
      }
    },
    title: "Marketing"
  };
});

const quoting = computed(() => {
  if (!user.loginable.show_backnine) return null;
  return {
    title: "Quoting",
    icon: mdiCalculator,
    props: { link: true, to: { name: "Quotes" } }
  };
});

const quoteAndApply = computed(() => {
  const color = isHoliday() ? "primary" : "accent";
  return {
    title: "Quote & Apply",
    icon: mdiRocketLaunch,
    props: {
      link: true,
      to: { name: "QuoteAndApply" },
      id: "quote-and-apply-nav"
    },
    color,
    titleClass: `text-${color}`,
    order: 2
  };
});

const commissions = computed(() => {
  if (user.permissions[PERMISSION.ACCOUNTING]) {
    return {
      title: "Commissions",
      icon: mdiCash,
      props: {
        id: "commissions-nav"
      },
      subItems: [
        {
          title: "B9 Dashboard",
          props: {
            link: true,
            to: { name: "BackNineCommissionsDashboard" }
          }
        },
        {
          title: "B9 Statements",
          props: {
            link: true,
            to: { name: "BackNineCommissionsStatements" }
          }
        },
        {
          title: "Pending Transactions",
          props: {
            link: true,
            to: { name: "BackNinePendingTransactions" }
          }
        },
        {
          title: "Commissions",
          props: { link: true, to: { name: "Commissions" } }
        }
      ]
    };
  } else if (!user.loginable.hide_commission && user.loginable.show_backnine) {
    return {
      title: "Commissions",
      props: { link: true, to: { name: "Commissions" } },
      icon: mdiCurrencyUsd
    };
  }

  return null;
});

const products = computed(() => {
  if (!user.loginable.show_backnine || user.loginable.hide_commission) {
    return null;
  }
  return {
    title: "Products",
    props: { link: true, to: { name: "Products" } },
    icon: mdiCubeOutline
  };
});

const formMapping = computed(() => {
  if (!user.permissions[PERMISSION.FORM_MAPPING]) return null;
  return {
    title: "Form Mapping",
    props: { link: true, to: { name: "FormMappingForms" } },
    icon: mdiFileDocumentEdit
  };
});

const caseManagerView = computed(() => {
  if (!user.loginable.is_case_manager) return null;
  return {
    title: "Case Management",
    icon: mdiAccountSupervisor,
    props: { link: true, to: { name: "CaseManagement" } }
  };
});

const reports = computed(() => {
  if (user.isGroupTwoPlus) {
    return {
      title: "Reports",
      icon: mdiFileChart,
      subItems: [
        {
          title: "BackNine",
          props: { link: true, to: { name: "BackNineReportView" } }
        },
        {
          title: "Case Managers",
          props: {
            link: true,
            to: { name: "CaseManagementReportView" }
          }
        },
        {
          title: "Holidays",
          props: { link: true, to: { name: "HolidaysView" } }
        },
        {
          title: "Marketing Managers",
          props: {
            link: true,
            to: { name: "MarketingManagerReportView" }
          }
        },
        {
          title: "Personal",
          props: { link: true, to: { name: "PersonalReportView" } }
        },
        {
          title: "Scorecard",
          props: { link: true, to: { name: "ScorecardReport" } }
        }
      ]
    };
  }

  return {
    title: "Reports",
    props: { link: true, to: { name: "PersonalReportView" } },
    icon: mdiFileChart
  };
});

const scorecard = computed(() => {
  if (user.isGroupOne && user.permissions[PERMISSION.SCORECARD]) {
    return {
      title: "Scorecard",
      props: { link: true, to: { name: "ScorecardReport" } },
      icon: mdiGolf
    };
  }

  return null;
});

const formsAndApplications = computed(() => {
  if (!user.loginable.show_backnine) return null;
  return {
    title: "Forms & Applications",
    icon: mdiFileDocumentOutline,
    props: { link: true, to: { name: "FormsAndApplicationsView" } }
  };
});

const routes = computed(() => {
  return [
    {
      title: "Home",
      props: {
        link: true,
        to: { name: "Home" },
        id: "v-step-3"
      },
      icon: mdiHome,
      order: 1
    },
    {
      icon: mdiBriefcaseVariant,
      title: "Cases",
      props: {
        link: true,
        to: { name: "CasesTable" },
        id: "cases-nav"
      }
    },
    {
      title: "Tables",
      icon: mdiTable,
      props: {
        link: true,
        to: { name: "Tables" }
      }
    },
    formsAndApplications.value,
    commissions.value,
    formMapping.value,
    emailMarketing.value,
    {
      title: "Help Center",
      icon: mdiLifebuoy,
      props: {
        link: true,
        to: { name: "HelpCenter" }
      }
    },
    quoteAndApply.value,
    quoting.value,
    reports.value,
    scorecard.value,
    products.value,
    caseManagerView.value
  ].filter(Boolean);
});

const sidebarData = computed(() => {
  const sortFunc = (a, b) => {
    const anon = value => {
      if ("order" in value) return `${value.order}`;
      return value.title.toLowerCase();
    };

    return anon(a).localeCompare(anon(b));
  };

  return routes.value
    .map(route =>
      route.subItems
        ? {
            ...route,
            subItems: route.subItems.toSorted(sortFunc)
          }
        : route
    )
    .toSorted(sortFunc);
});
</script>
