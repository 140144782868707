<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="4">
          <text-field
            v-model="party.firstName"
            data-testid="new-party-first-name"
            data-lpignore="true"
            :prepend-inner-icon="mdiAccount"
            label="First Name"
            :success="firstNameValidation.success"
            :error-messages="firstNameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="4">
          <text-field
            v-model="party.middleName"
            data-testid="new-party-middle-name"
            data-lpignore="true"
            :prepend-inner-icon="mdiAccount"
            label="Middle Name (optional)"
            :success="Boolean(party.middleName)"
          />
        </v-col>
        <v-col cols="4">
          <text-field
            v-model="party.lastName"
            data-testid="new-party-last-name"
            data-lpignore="true"
            :prepend-inner-icon="mdiAccountMultiple"
            label="Last Name"
            :success="lastNameValidation.success"
            :error-messages="lastNameValidation.errorMessages"
          />
        </v-col>

        <v-col v-if="!props.hideRole" cols="12">
          <case-role-input
            v-model:roles="party.roles"
            v-model:beneficiary-amount="party.beneficiaryAmount"
            v-model:relationship="party.relationship"
            validation-scope="individual"
            type="Individual"
            :line="props.line"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-sheet color="section" class="pa-3 rounded">
        <h4 class="mb-3 text-h6">Optional Individual Information</h4>
        <v-row class="ma-0" dense>
          <v-col cols="12" lg="4">
            <text-field
              v-model="party.email"
              data-testid="new-party-email"
              data-lpignore="true"
              :prepend-inner-icon="mdiEmail"
              label="E-mail Address"
              :success="Boolean(party.email)"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <input-ssn
              v-model="party.ssn"
              data-lpignore="true"
              data-testid="ssn"
              name="ssn"
              :prepend-inner-icon="mdiAsterisk"
              label="SSN"
              required
              :loading="loadingSsn"
              :success="party.ssn && ssnValidation.success"
              :error-messages="loadingSsn ? [] : ssnValidation.errorMessages"
              :show-hidden="showSsn"
              @blur="validateSsn"
              @focus="showSsn = true"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <date-input
              v-model="party.birthdate"
              data-testid="birthdate"
              :prepend-inner-icon="mdiCalendar"
              label="Birthdate"
              :success="Boolean(party.birthdate)"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <div class="flex-row">
              <select-field
                v-model="party.gender"
                :prepend-inner-icon="mdiGenderMaleFemale"
                data-testid="gender"
                label="Gender"
                :items="GENDERS"
                :success="Boolean(party.gender)"
              />
            </div>
          </v-col>
          <v-col cols="12" lg="4">
            <currency-input
              v-model="party.income"
              data-testid="income"
              :prepend-inner-icon="mdiCurrencyUsd"
              label="Income"
              :success="Boolean(party.income)"
            />
          </v-col>
          <v-col v-if="!hideNetWorth" cols="12" lg="4">
            <currency-input
              v-model="party.netWorth"
              :prepend-inner-icon="mdiCurrencyUsd"
              data-testid="net-worth"
              label="Net Worth"
              :success="Boolean(party.netWorth)"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <text-field
              v-model="party.occupation"
              data-lpignore="true"
              :prepend-inner-icon="mdiBriefcaseVariant"
              data-testid="occupation"
              label="Occupation"
              :success="Boolean(party.occupation)"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <autocomplete-field
              v-model="party.dlState"
              :prepend-inner-icon="mdiCardAccountDetails"
              data-testid="drivers-license-state"
              label="DL State"
              :items="STATES"
              :success="Boolean(party.dlState)"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <text-field
              v-model="party.dlNumber"
              data-lpignore="true"
              :prepend-inner-icon="mdiPound"
              data-testid="drivers-license-number"
              label="DL Number"
              :success="Boolean(party.dlNumber)"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <phone-input
              v-model="party.phoneMobile"
              data-lpignore="true"
              data-testid="mobile-phone"
              label="Mobile Phone"
              :prepend-inner-icon="mdiPhone"
              :success="Boolean(party.phoneMobile)"
            />
          </v-col>
          <v-col cols="12">
            <h4 class="mb-3 text-h6">
              Address
              <app-button
                v-if="showCopyAddress"
                variant="text"
                :prepend-icon="mdiContentCopy"
                text="Copy from Insured"
                class="text-none"
                data-testid="copy-insured-address"
                @click="copyInsuredAddress"
              />
            </h4>
            <basic-address-input
              ref="address"
              v-model:street-address-model="party.homeAddress.street_address"
              v-model:city-model="party.homeAddress.city"
              v-model:state-model="party.homeAddress.state"
              v-model:zip-model="party.homeAddress.zip"
              type="home"
              autocomplete
              abbreviated
              no-autofill
              autofill-placeholder="Home Address"
              autofill-label="Home Address"
              data-testid="new-party"
              :street-address-validation="streetValidation"
              :city-validation="cityValidation"
              :state-validation="stateValidation"
              :zip-validation="zipValidation"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script setup>
import CaseRoleInput from "@/components/cases/case-dialogs/CaseRoleInput.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import DateInput from "@/components/shared/DateInput.vue";
import InputSsn from "@/components/shared/InputSsn.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import {
  NewCaseIndividual,
  NewCaseIndividualToCreateRequest
} from "@/factories/Party";

import { STATES } from "@/data/states";

import { uniqueSsn, ssn } from "@/util/vuelidateValidators";

import { createContractParty } from "@/api/contract-party.service";
import { caseCreateContractParty } from "@/api/cases.service";
import { ref } from "vue";
import { computedValidation, someTextValidator } from "@/util/helpers";
import useVuelidate from "@vuelidate/core";
import {
  mdiAccount,
  mdiAccountMultiple,
  mdiEmail,
  mdiAsterisk,
  mdiCalendar,
  mdiGenderMaleFemale,
  mdiCurrencyUsd,
  mdiBriefcaseVariant,
  mdiCardAccountDetails,
  mdiPound,
  mdiPhone,
  mdiContentCopy
} from "@mdi/js";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

const GENDERS = ["Male", "Female"];

const props = defineProps({
  caseId: { type: Number, required: false, default: null },
  line: { type: String, required: true },
  hideRole: Boolean,
  hideNetWorth: Boolean,
  insuredAddress: {
    type: Object,
    required: false,
    default: () => null
  }
});

const party = ref(NewCaseIndividual());
const snackbar = useSnackbarStore();
const ssnValidationMessage = ref("");
const loadingSsn = ref(false);
const showSsn = ref(false);

const v$ = useVuelidate(
  {
    party: {
      firstName: {
        required: v => someTextValidator(true, v, 2)
      },
      lastName: {
        required: v => someTextValidator(true, v, 2)
      },
      ssn: {
        valid: v => !v || !ssnValidationMessage.value
      },
      homeAddress: {
        street_address: {
          required: v => !v || someTextValidator(true, v, 2)
        },
        city: {
          required: v => !v || someTextValidator(true, v, 2)
        },
        state: {
          required: v => !v || someTextValidator(true, v, 2)
        },
        zip: {
          required: v => !v || someTextValidator(true, v, 2)
        }
      }
    }
  },
  { party },
  { $scope: "individual", $autoDirty: true }
);

const firstNameValidation = computedValidation(v$.value.party.firstName, {
  required: "Required"
});
const lastNameValidation = computedValidation(v$.value.party.lastName, {
  required: "Required"
});
const streetValidation = computedValidation(
  v$.value.party.homeAddress.street_address,
  { required: "Required" }
);
const cityValidation = computedValidation(v$.value.party.homeAddress.city, {
  required: "Required"
});
const stateValidation = computedValidation(v$.value.party.homeAddress.state, {
  required: "Required"
});
const zipValidation = computedValidation(v$.value.party.homeAddress.zip, {
  required: "Required"
});

const ssnValidation = computedValidation(v$.value.party.ssn, {
  valid: ssnValidationMessage
});

let showCopyAddress = false;
if (props.insuredAddress) {
  showCopyAddress = ["street_address", "city", "state", "zip"].some(v =>
    Boolean(props.insuredAddress[v])
  );
}

function copyInsuredAddress() {
  party.value.homeAddress.street_address = props.insuredAddress.street_address;
  party.value.homeAddress.city = props.insuredAddress.city;
  party.value.homeAddress.state = props.insuredAddress.state;
  party.value.homeAddress.zip = props.insuredAddress.zip;
}

async function ssnValidator() {
  if (!party.value.ssn) return "";
  if (!ssn(party.value.ssn)) return "Invalid SSN";
  loadingSsn.value = true;
  let message = "";
  try {
    const isUnique = await uniqueSsn(party.value.ssn);
    if (!isUnique) message = "A party exists with this SSN";
  } catch (e) {
    message = "Unable to verify SSN, please try again";
  } finally {
    loadingSsn.value = false;
  }
  loadingSsn.value = false;
  return message;
}
async function validateSsn() {
  showSsn.value = false;
  ssnValidationMessage.value = await ssnValidator();
}
async function create() {
  if (loadingSsn.value) return;
  const valid = await v$.value.$validate();
  if (!valid) return;

  let result;

  const body = NewCaseIndividualToCreateRequest(party.value);

  try {
    if (props.caseId) {
      result = await caseCreateContractParty(props.caseId, body);
    } else {
      result = await createContractParty(body);
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }

  return result;
}

defineExpose({ create });
</script>
