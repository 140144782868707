export default class TableHeader {
  text = "";
  value = "";
  map = "";
  filterType = TableHeader.STRING_TYPE;
  selectableOptions = [];
  selectableOptionsFn = [];
  sortable = false;
  filterable = undefined;
  isMandatory = false;
  isQueryFilter = false;
  sortFilterMap = "";
  isAdditional = false;
  displayMap = "";
  icon = "";
  showIf = null;
  order = 99;
  filterOrder = 99;
  sortKey = "";
  sendFalse = false;
  align = null;
  width = null;
  filter = undefined;
  sortRaw = undefined;
  isCurrency = false;
  futureDateValues = false;
  showInTableIf = null;
  checkboxValues = { true: true, false: false };
  hideDisplayValue = false;
  filterMap = null;

  static IS_SORTABLE = { sortable: true };
  static IS_FILTERABLE = { filterable: true };
  static IS_QUERY_FILTER = { isQueryFilter: true };
  static IS_MANDATORY = { isMandatory: true };
  static IS_ADDITIONAL = { isAdditional: true };

  static NUMBER_TYPE = "number";
  static STRING_TYPE = "string";
  static DATE_TYPE = "date";
  static ACTION_TYPE = "action";
  static HIDDEN_TYPE = "hidden";
  static AUTOCOMPLETE_TYPE = "autocomplete";
  static SELECT_TYPE = "select";
  static MULTI_SELECT_TYPE = "MULTI_SELECT";
  static ADVISOR_SEARCH_TYPE = "ADVISOR_SEARCH";
  static ADVISOR_PAYMENT_SEARCH_TYPE = "ADVISOR_SEARCH_PAYMENT";
  static PAYOR_SEARCH_TYPE = "PAYOR_SEARCH";
  static POLICY_SEARCH_TYPE = "POLICY_SEARCH";
  static CONTRACT_PARTY_SEARCH_TYPE = "CONTRACT_PARTY_SEARCH";
  static CARRIER_SEARCH_TYPE = "CARRIER_SEARCH";
  static AGENT_SEARCH_TYPE = "AGENT_SEARCH";
  static PRODUCT_SEARCH_TYPE = "PRODUCT_SEARCH";
  static CASE_MANAGER_SEARCH_TYPE = "CASE_MANAGER_SEARCH";
  static MARKETING_MANAGER_SEARCH_TYPE = "MARKETING_MANAGER_SEARCH";
  static CHECKBOX_TYPE = "checkbox";
  static FORM_SET_SEARCH_TYPE = "FORM_SET_SEARCH";
  static PRICING_PLAN_SEARCH_TYPE = "PRICING_PLAN_SEARCH";
  static VENDOR_SEARCH_TYPE = "VENDOR_SEARCH_TYPE";
  static AFFILIATION_SEARCH_TYPE = "AFFILIATION_SEARCH_TYPE";
  static NUMBER_RANGE = "NUMBER_RANGE";
  static FORM_CATEGORY = "FORM_CATEGORY";
  static GLOBAL_SEARCH_FILTER_TYPE = "GLOBAL_SEARCH_FILTER_TYPE";
  static PHONE_TYPE = "PHONE_TYPE";

  // Helpers
  static IS_CHECKBOX_TYPE = { filterType: this.CHECKBOX_TYPE };
  static IS_NUMBER_FILTER_TYPE = { filterType: this.NUMBER_TYPE };
  static IS_STRING_FILTER_TYPE = { filterType: this.STRING_TYPE };
  static IS_DATE_FILTER_TYPE = { filterType: this.DATE_TYPE };
  static IS_ACTION_FILTER_TYPE = { filterType: this.ACTION_TYPE };
  static IS_HIDDEN_FILTER_TYPE = { filterType: this.HIDDEN_TYPE };
  static IS_SELECT_FILTER_TYPE = { filterType: this.SELECT_TYPE };
  static IS_AUTOCOMPLETE_FILTER_TYPE = { filterType: this.AUTOCOMPLETE_TYPE };
  static IS_NUMBER_RANGE_TYPE = { filterType: this.NUMBER_RANGE };

  static IS_CURRENCY = { isCurrency: true };
  static SEND_FALSE = { sendFalse: true };

  static IS_PRICING_PLAN_SEARCH_TYPE = {
    filterType: this.PRICING_PLAN_SEARCH_TYPE,

  };
  static IS_VENDOR_SEARCH_TYPE = {
    filterType: this.VENDOR_SEARCH_TYPE
  };

  static IS_AFFILIATION_SEARCH_TYPE = {
    filterType: this.AFFILIATION_SEARCH_TYPE
  };

  static IS_MULTI_SELECT_FILTER_TYPE = { filterType: this.MULTI_SELECT_TYPE };
  static IS_ADVISOR_SEARCH_FILTER_TYPE = {
    filterType: this.ADVISOR_SEARCH_TYPE
  };
  static IS_ADVISOR_PAYMENT_SEARCH_FILTER_TYPE = {
    filterType: this.ADVISOR_PAYMENT_SEARCH_TYPE
  };
  static IS_PAYOR_SEARCH_FILTER_TYPE = { filterType: this.PAYOR_SEARCH_TYPE };
  static IS_POLICY_SEARCH_FILTER_TYPE = { filterType: this.POLICY_SEARCH_TYPE };
  static IS_CONTRACT_PARTY_SEARCH_FILTER_TYPE = {
    filterType: this.CONTRACT_PARTY_SEARCH_TYPE
  };
  static IS_CARRIER_SEARCH_FILTER_TYPE = {
    filterType: this.CARRIER_SEARCH_TYPE
  };
  static IS_AGENT_SEARCH_FILTER_TYPE = { filterType: this.AGENT_SEARCH_TYPE };
  static IS_PRODUCT_SEARCH_FILTER_TYPE = {
    filterType: this.PRODUCT_SEARCH_TYPE
  };
  static IS_CASE_MANAGER_SEARCH_FILTER_TYPE = {
    filterType: this.CASE_MANAGER_SEARCH_TYPE
  };
  static IS_MARKETING_MANAGER_SEARCH_FILTER_TYPE = {
    filterType: this.MARKETING_MANAGER_SEARCH_TYPE
  };
  static IS_FORM_SET_SEARCH = {
    filterType: this.FORM_SET_SEARCH_TYPE
  };
  static IS_FORM_CATEGORY_SEARCH = { filterType: this.FORM_CATEGORY };

  static IS_GLOBAL_SEARCH_FILTER_TYPE = {
    filterType: this.GLOBAL_SEARCH_FILTER_TYPE
  };

  static IS_PHONE_TYPE = {
    filterType: this.PHONE_TYPE
  };

  static TOTAL_ORDER = order => ({ order, filterOrder: order });

  constructor({
    text = "",
    value = "",
    map = "",
    filterType = TableHeader.STRING_TYPE,
    selectableOptions = [],
    selectableOptionsFn = null,
    sortable = false,
    filterable = false,
    isMandatory = false,
    isQueryFilter = false,
    sortFilterMap = "",
    isAdditional = false,
    displayMap = "",
    icon = "",
    showIf = null,
    order = 99,
    filterOrder = 99,
    sortKey = "",
    sendFalse = false,
    align = null,
    width = null,
    filter = null,
    sortRaw = null,
    isCurrency = false,
    futureDateValues = false,
    showInTableIf = null,
    checkboxValues = { true: true, false: false },
    hideDisplayValue = false,
    filterMap = null
  }) {
    this.text = text;
    this.value = value;
    this.map = map;
    this.filterType = filterType;
    this.selectableOptions = selectableOptions;
    this.selectableOptionsFn = selectableOptionsFn;
    this.sortable = sortable;
    this.filterable = filterable;
    this.isMandatory = isMandatory;
    this.isQueryFilter = isQueryFilter;
    this.sortFilterMap = sortFilterMap;
    this.isAdditional = isAdditional;
    this.displayMap = displayMap;
    this.icon = icon;
    this.showIf = showIf;
    this.order = order;
    this.filterOrder = filterOrder;
    this.sortKey = sortKey;
    this.sendFalse = sendFalse;
    this.align = align;
    this.width = width;
    this.filter = filter;
    this.sortRaw = sortRaw;
    this.isCurrency = isCurrency;
    this.futureDateValues = futureDateValues;
    this.showInTableIf = showInTableIf;
    this.checkboxValues = checkboxValues;
    this.hideDisplayValue = hideDisplayValue;
    this.filterMap = filterMap;
  }
}
