<template>
  <v-fade-transition mode="out-in">
    <v-row v-if="loading" key="loading" dense>
      <v-col cols="12" class="pb-0">
        <v-skeleton-loader type="card" />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-sheet color="section" class="py-3 px-0">
          <v-skeleton-loader type="card" />
        </v-sheet>
      </v-col>
    </v-row>
    <v-col v-else key="loaded" dense class="pa-1 mb-12">
      <carrier-view-card />
      <v-card flat tile>
        <v-tabs v-model="activeTab" show-arrows color="primary">
          <v-tab
            v-for="tab in tabs"
            :key="tab.page"
            class="text-none"
            :data-testid="`tab-${tab.title}`"
          >
            <v-icon class="pr-1" :icon="tab.icon" />
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-window v-model="activeTab" touchless>
          <v-tabs-window-item
            v-for="(tab, index) in tabs"
            :key="tab.page + 'component'"
            class="section"
          >
            <component
              :is="tab.component"
              :active="activeTab === index"
              v-bind="tab.props || {}"
            />
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card>
    </v-col>
  </v-fade-transition>
</template>
<script setup>
import CarrierViewCard from "@/components/carriers/CarrierViewCard.vue";
import CarrierViewDetails from "@/components/carriers/CarrierViewDetails.vue";
import CarrierViewLineDetails from "@/components/carriers/CarrierViewLineDetails.vue";
import CarrierViewDocuments from "@/components/carriers/CarrierViewDocuments.vue";
import CarrierViewCompBuilders from "@/components/carriers/CarrierViewCompBuilders.vue";
import CarrierViewApi from "@/components/carriers/CarrierViewApi.vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useInstanceStore } from "@/stores/instance";
import { useCarrierView } from "@/stores/carrier-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { parseErrorMessage } from "@/util/helpers";
import { computed, onBeforeUnmount, ref, markRaw, watch } from "vue";
import { useRouter } from "vue-router";
import {
  mdiAccountReactivate,
  mdiBed,
  mdiBookAlphabet,
  mdiCalendarLock,
  mdiCurrencyUsd,
  mdiFileDocumentEdit,
  mdiFileDocumentMultiple,
  mdiHumanCane
} from "@mdi/js";

const props = defineProps({
  id: { type: Number, required: true },
  page: { type: String, default: "details" }
});

const router = useRouter();
const snackbar = useSnackbarStore();
const carrierView = useCarrierView();
const { breadcrumb } = storeToRefs(useInstanceStore());
const { isGroupTwoPlus, isGroupFour } = storeToRefs(useUserStore());
const { name, activesByLine } = storeToRefs(carrierView);

const head = useHead({ title: "Carrier Viewer" });
const activeTab = ref(0);
const loading = ref(false);

onBeforeUnmount(() => (breadcrumb.value = ""));

const tabs = computed(() => {
  const tabs = [
    {
      title: "General Settings",
      page: "details",
      icon: mdiFileDocumentEdit,
      component: markRaw(CarrierViewDetails)
    }
  ];

  if (activesByLine.value?.annuity) {
    tabs.push({
      title: "Annuity",
      page: "annuity",
      icon: mdiCalendarLock,
      component: markRaw(CarrierViewLineDetails),
      props: { line: "annuity" }
    });
  }

  if (activesByLine.value?.disability) {
    tabs.push({
      title: "Disability",
      page: "disability",
      icon: mdiHumanCane,
      component: markRaw(CarrierViewLineDetails),
      props: { line: "disability" }
    });
  }

  if (activesByLine.value?.life) {
    tabs.push({
      title: "Life",
      page: "life",
      icon: mdiAccountReactivate,
      component: markRaw(CarrierViewLineDetails),
      props: { line: "life" }
    });
  }

  if (activesByLine.value?.ltc) {
    tabs.push({
      title: "LTC",
      page: "ltc",
      icon: mdiBed,
      component: markRaw(CarrierViewLineDetails),
      props: { line: "ltc" }
    });
  }

  tabs.push({
    title: "Documents",
    page: "documents",
    icon: mdiFileDocumentMultiple,
    component: markRaw(CarrierViewDocuments)
  });

  if (isGroupTwoPlus.value) {
    tabs.push({
      title: "Comp Builders",
      page: "comp-builders",
      icon: mdiCurrencyUsd,
      component: markRaw(CarrierViewCompBuilders)
    });
  }

  if (isGroupFour.value) {
    tabs.push({
      title: "API",
      page: "api",
      icon: mdiBookAlphabet,
      component: markRaw(CarrierViewApi)
    });
  }

  return tabs;
});

async function getCarrier() {
  try {
    loading.value = true;
    await carrierView.fetchCarrierData(props.id);
    breadcrumb.value = name.value;
    head.patch({ title: carrierView.name });

    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;
    else activeTab.value = tabs.value.findIndex(tab => tab.defaultTab);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    await router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

function syncQueryToTab() {
  if (!router) return;
  const tab = tabs.value[activeTab.value];
  if (!tab) return;
  let page = tab.page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

getCarrier();
</script>
