import { defineStore } from "pinia";
import { Product } from "@/factories/Product";
import { getProduct, updateProduct } from "@/api/products.service";

const toFrac = val => Number(val) / 100;

export const useProductView = defineStore("product-view", {
  state: () => Product(),
  actions: {
    async loadProduct(id) {
      this.$reset();
      const product = await getProduct(id);
      this.$patch(product);
    },
    updateName() {
      return updateProduct(this.id, { name: this.name });
    },
    updateLine() {
      return updateProduct(this.id, { line: this.line?.toLowerCase() });
    },
    updateLinePercentage() {
      return updateProduct(this.id, {
        line_percentage: toFrac(this.linePercentage)?.toString()
      });
    },
    updateActiveYears() {
      return updateProduct(this.id, {
        active_years: parseInt(this.activeYears)
      });
    },
    updateStreetFirst() {
      return updateProduct(this.id, { street_first: toFrac(this.streetFirst) });
    },
    updateStreetExcess() {
      return updateProduct(this.id, {
        street_excess: toFrac(this.streetExcess)
      });
    },
    updateStreetRenewal() {
      return updateProduct(this.id, {
        street_renewal: toFrac(this.streetRenewal)
      });
    },
    updateStreetPua() {
      return updateProduct(this.id, { street_pua: toFrac(this.streetPua) });
    },
    updateTotalFirst() {
      return updateProduct(this.id, { total_first: toFrac(this.totalFirst) });
    },
    updateTotalExcess() {
      return updateProduct(this.id, { total_excess: toFrac(this.totalExcess) });
    },
    updateTotalRenewal() {
      return updateProduct(this.id, {
        total_renewal: toFrac(this.totalRenewal)
      });
    },
    updateTotalPua() {
      return updateProduct(this.id, { total_pua: toFrac(this.totalPua) });
    },
    updateChargeback() {
      return updateProduct(this.id, { chargeback: this.chargeback });
    },
    updateSurrenderPeriod() {
      return updateProduct(this.id, {
        surrender_period: parseInt(this.surrenderPeriod)
      });
    },
    updateRollingTarget() {
      return updateProduct(this.id, { rolling_target: this.rollingTarget });
    },
    updateAgeNearest() {
      return updateProduct(this.id, {
        age_nearest: this.ageNearest?.toString()
      });
    },
    updateEDelivery() {
      return updateProduct(this.id, {
        carrier_e_delivery: this.eDelivery?.toString()
      });
    },
    updateConversion() {
      return updateProduct(this.id, { conversion: this.conversion });
    },
    updateAdvisorGuide() {
      return updateProduct(this.id, {
        advisor_guide_id: this.advisorGuide?.id || null
      });
    },
    updateConsumerGuide() {
      return updateProduct(this.id, {
        consumer_guide_id: this.consumerGuide?.id || null
      });
    },
    updateUnderwritingGuide() {
      return updateProduct(this.id, {
        underwriting_guide_id: this.underwritingGuide?.id || null
      });
    },
    updateType() {
      return updateProduct(this.id, { type: this.type });
    },
    updateTraits() {
      return updateProduct(this.id, { traits: this.traits });
    },
    updateFormSet() {
      return updateProduct(this.id, { form_set_id: this.formSet });
    },
    updateStrifeEnabled() {
      return updateProduct(this.id, { strife_enabled: this.strifeEnabled });
    },
    updateAdvisorUseOnly() {
      return updateProduct(this.id, { advisor_use_only: this.advisorUseOnly });
    },
    updateLimitToPreferredAge() {
      return updateProduct(this.id, {
        limit_to_preferred_age: this.limitToPreferredAge
      });
    },
    updateLimitToStandardAge() {
      return updateProduct(this.id, {
        limit_to_standard_age: this.limitToStandardAge
      });
    },
    updateInstantAps() {
      return updateProduct(this.id, { instant_aps: this.instantAps });
    },
    updateCriticalIllnessRider() {
      return updateProduct(this.id, {
        critical_illness_rider: this.criticalIllnessRider
      });
    },
    updateChronicIllnessRider() {
      return updateProduct(this.id, {
        chronic_illness_rider: this.chronicIllnessRider
      });
    },
    updateNoCostChronicIllnessRider() {
      return updateProduct(this.id, {
        no_cost_chronic_illness_rider: this.noCostChronicIllnessRider
      });
    },
    updateNoCostCriticalIllnessRider() {
      return updateProduct(this.id, {
        no_cost_critical_illness_rider: this.noCostCriticalIllnessRider
      });
    },
    updateLtcRider() {
      return updateProduct(this.id, { ltc_rider: this.ltcRider });
    },
    updateVitalityRider() {
      return updateProduct(this.id, { vitality_rider: this.vitalityRider });
    },
    updateSection1035Exchange() {
      return updateProduct(this.id, {
        section_1035_exchange: this.section1035Exchange
      });
    },
    updateSolves() {
      return updateProduct(this.id, { solves: this.solves });
    },
    updateMinAge() {
      return updateProduct(this.id, { min_age: this.minAge });
    },
    updateMaxAge() {
      return updateProduct(this.id, { max_age: this.maxAge });
    },
    updateMinDeathBenefit() {
      return updateProduct(this.id, {
        min_death_benefit: this.minDeathBenefit
      });
    },
    updateMaxDeathBenefit() {
      return updateProduct(this.id, {
        max_death_benefit: this.maxDeathBenefit
      });
    },
    updateMinPremium() {
      return updateProduct(this.id, { min_premium: this.minPremium });
    },
    updateMaxPremium() {
      return updateProduct(this.id, { max_premium: this.maxPremium });
    },
    updatePayDuration() {
      return updateProduct(this.id, { pay_duration: this.payDuration });
    },
    updatePrimaryProduct() {
      return updateProduct(this.id, {
        primary_product: this.primaryProduct ? "1" : "0"
      });
    },
    updateFreeWithdrawals() {
      return updateProduct(this.id, {
        free_withdrawals: this.freeWithdrawals
      });
    }
  }
});
