<template>
  <v-card flat tile>
    <v-card-text class="pb-0">
      <v-alert
        v-if="test"
        variant="outlined"
        type="warning"
        style="font-size: 1rem"
      >
        This eApp was created in test mode
      </v-alert>
      <v-row
        class="ma-0 justify-space-between align-center hidden-md-and-down"
        dense
      >
        <div data-testid="card-insured">
          <strong style="font-size: 1.2rem">
            {{ insuredName }}
          </strong>
          <br />
          Insured
        </div>
        <div v-if="product.id">
          <carrier-image
            :id="carrier.id"
            data-testid="card-carrier-avatar"
            :image="carrier.avatarUrl"
          />
        </div>
      </v-row>
      <v-row class="ma-0 justify-center align-center hidden-lg-and-up" dense>
        <div class="flex-column justify-center text-center">
          <div
            v-if="product.id"
            class="ma-3"
            style="height: 100px; width: 300px"
          >
            <carrier-image
              :id="carrier.id"
              data-testid="card-carrier-avatar"
              :image="carrier.avatarUrl"
            />
          </div>

          <div
            data-testid="card-insured"
            class="ma-3 justify-center flex-column"
          >
            <div>
              <strong style="font-size: 1.2rem">
                {{ insuredName }}
              </strong>
            </div>
            <div>Insured</div>
          </div>
        </div>
      </v-row>
      <v-divider />
      <template v-for="(detailGroup, index) in detailGroups" :key="index">
        <card-items :items="detailGroup" />
        <v-divider />
      </template>
      <v-row v-if="nextStep || locked" class="ma-0" dense>
        <v-col cols="12">
          <v-alert
            v-if="nextStep"
            color="primary"
            elevation="2"
            type="info"
            density="compact"
            style="font-size: 1rem"
            data-testid="card-next-step"
          >
            Next Step: {{ nextStep }}
          </v-alert>
          <v-alert
            v-if="locked"
            elevation="2"
            type="error"
            dense
            style="font-size: 1rem"
            data-testid="card-locked-eapp"
          >
            This eApp is Locked.
            <app-button
              class="ml-3 text-none"
              data-testid="unlock-eapp"
              size="small"
              color="black"
              @click="unlock"
            >
              Unlock eApp
            </app-button>
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import LeadContactedDialog from "@/dialogs/LeadContactedDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import CarrierImage from "@/components/shared/CarrierImage.vue";
import CardItems from "@/components/shared/card-items/CardItems.vue";

import {
  yearsAndMonths,
  timestampFormatter,
  currencyFormat,
  formatToFullState,
  formatPercentage,
  modeFormat,
  formatFrequency,
  getRouterLink
} from "@/util/helpers";
import { storeToRefs } from "pinia";
import { markRaw, ref, watchEffect } from "vue";
import parseISO from "date-fns/parseISO";

import { useEappViewStore } from "@/stores/eapp-view";
import { useDialogStore } from "@/stores/dialog";
import {
  routerLinkItem,
  textItem
} from "@/components/shared/card-items/card-items";

const eAppStore = useEappViewStore();
const dialog = useDialogStore();

const {
  nextStep,
  carrier,
  insured,
  jointInsured,
  test,
  latestQuotes,
  agent,
  approvedDomain,
  product,
  status,
  isCompleted,
  namedStep,
  selectedType,
  policyNumber,
  hasPhysician,
  humanApiInvitedAt,
  tia,
  orderNumber,
  examDate,
  examAddress,
  createdAt,
  referredBy,
  followUpAt,
  id,
  followUpIsScheduled,
  locked
} = storeToRefs(eAppStore);

const detailGroups = ref([]);

const isLTC = latestQuotes.value.insured.productCategory === "LTC";
const jointLtc = latestQuotes.value.insured.partnerDiscount === "both" && isLTC;
const insuredName = jointLtc
  ? `${insured.value.name} and ${jointInsured.value.name}`
  : insured.value.name;

function generateDetailGroups() {
  detailGroups.value.splice(0, detailGroups.value.length);
  const insuredQuote = latestQuotes.value.insured;
  const jointInsuredQuote = latestQuotes.value.jointInsured;

  const isLinkedBenefit = insuredQuote.productCategory === "Linked Benefit";

  const metaDetailItems = [];
  const productDetailItems = [];
  const insuredDetailItems = [];
  metaDetailItems.push(
    routerLinkItem({
      title: "Agent",
      text: agent.value.name,
      to: getRouterLink("Agent", agent.value.id)
    })
  );

  if (approvedDomain.value?.id) {
    if (approvedDomain.value?.isArchived) {
      metaDetailItems.push(
        routerLinkItem({
          title: "Website",
          text: approvedDomain.value.domain,
          tooltipText: `${approvedDomain.value.domain} is a deleted Quote & Apply site`
        })
      );
    } else {
      metaDetailItems.push(
        routerLinkItem({
          title: "Website",
          text: approvedDomain.value.domain,
          to: getRouterLink("ApprovedDomain", approvedDomain.value?.id)
        })
      );
    }
  }

  if (status.value) {
    metaDetailItems.push(
      textItem({
        title: "Status",
        text: status.value
      })
    );
  }

  if (!isCompleted.value) {
    metaDetailItems.push(
      textItem({
        title: "Current Step",
        text: namedStep.value
      })
    );
  }

  if (insuredQuote.targetPremium) {
    metaDetailItems.push(
      textItem({
        title: "Target Premium",
        text: currencyFormat(insuredQuote.targetPremium)
      })
    );
  }

  if (createdAt.value) {
    metaDetailItems.push(
      textItem({
        title: "Date Created",
        text: timestampFormatter(createdAt.value, "none", "basic")
      })
    );
  }

  if (referredBy.value) {
    metaDetailItems.push(
      textItem({
        title: "Referred By",
        text: referredBy.value
      })
    );
  }

  // make editable
  let followUpText = "N/A";
  if (followUpAt.value) {
    try {
      followUpText = timestampFormatter(
        followUpAt.value,
        null,
        "full-date-time"
      );
    } catch (e) {
      // do nothing
    }
  }

  metaDetailItems.push(
    textItem({
      title: followUpIsScheduled.value ? "Appointment" : "Follow Up",
      text: followUpText,
      onClick: async () => {
        let date;

        date = parseISO(followUpAt.value);
        if (date.toString() === "Invalid Date") date = null;

        const res = await dialog.showDialog({
          component: markRaw(LeadContactedDialog),
          eAppId: id.value,
          title: "Change Follow Up",
          date,
          followUpIsScheduled: Boolean(followUpIsScheduled.value)
        });
        if (!res?.id) return;
        followUpAt.value = res.followUp;
        followUpIsScheduled.value = res.followUpIsScheduled;
      }
    })
  );

  if (product.value?.id) {
    productDetailItems.push(
      routerLinkItem({
        title: "Product",
        text: product.value.name,
        to: getRouterLink("Product", product.value.id)
      })
    );
  }

  if (carrier.value?.name) {
    productDetailItems.push(
      routerLinkItem({
        title: "Carrier",
        text: carrier.value.name,
        to: getRouterLink("Carrier", carrier.value.id)
      })
    );
  }

  if (insuredQuote.state) {
    productDetailItems.push(
      textItem({
        title: "State",
        text: formatToFullState(insuredQuote.state)
      })
    );
  }

  if (insuredQuote.productCategory) {
    productDetailItems.push(
      textItem({
        title: "Product Category",
        text: insuredQuote.productCategory
      })
    );
  }

  if (
    !["aiul", "ul", "piul", "wholeLife"].includes(selectedType.value) &&
    !isLTC &&
    insuredQuote.solve
  ) {
    productDetailItems.push(
      textItem({
        title: "Solve",
        text: insuredQuote.solve
      })
    );
  }

  if (insuredQuote.ltcRider && !isLTC) {
    productDetailItems.push(
      textItem({
        title: "LTC Rider",
        text: "Yes"
      })
    );
  }

  if (insuredQuote.chronicIllnessRider && !isLTC) {
    productDetailItems.push(
      textItem({
        title: "Chronic Rider",
        text: "Yes"
      })
    );
  }

  if (insuredQuote.ltcRider && !isLTC) {
    productDetailItems.push(
      textItem({
        title: "Chronic/LTC Rider Percentage",
        text: formatPercentage(insuredQuote.ltcRiderPercentage, {
          decimalLength: 2
        })
      })
    );
  }

  if (insuredQuote.faceAmount && !isLTC) {
    productDetailItems.push(
      textItem({
        title: "Death Benefit",
        text: currencyFormat(insuredQuote.faceAmount, 0)
      })
    );
  }

  if ((isLTC || isLinkedBenefit) && insuredQuote.monthlyBenefit) {
    productDetailItems.push(
      textItem({
        title: "Monthly Benefit",
        text: currencyFormat(insuredQuote.monthlyBenefit, 0)
      })
    );
  }

  if ((isLTC || isLinkedBenefit) && insuredQuote.benefitPeriod) {
    productDetailItems.push(
      textItem({
        title: "Benefit Period",
        text: yearsAndMonths(insuredQuote.benefitPeriod)
      })
    );
  }

  if (isLTC && insuredQuote.eliminationPeriod) {
    productDetailItems.push(
      textItem({
        title: "Elimination Period",
        text: `${insuredQuote.eliminationPeriod} Days`
      })
    );
  }

  if (isLTC) {
    productDetailItems.push(
      textItem({
        title: "Home Health Care Waiver of Premium",
        text: insuredQuote.homeHealthCareWaiver ? "Yes" : "No"
      })
    );
  }

  if (isLTC && jointLtc) {
    productDetailItems.push(
      textItem({
        title: "Shared Care",
        text: insuredQuote.sharedCare ? "Yes" : "No"
      })
    );
  }

  if (isLTC && jointLtc) {
    productDetailItems.push(
      textItem({
        title: "Joint Waiver of Premium",
        text: insuredQuote.jointWaiverOfPremium ? "Yes" : "No"
      })
    );
  }

  if (isLTC && insuredQuote.cashBenefitPercentage) {
    productDetailItems.push(
      textItem({
        title: "Cash Benefit Percentage",
        text: formatPercentage(insuredQuote.cashBenefitPercentage, {
          isDecimal: true,
          decimalLength: 2
        })
      })
    );
  }

  if ((isLTC || isLinkedBenefit) && insuredQuote.inflationPercentage) {
    productDetailItems.push(
      textItem({
        title: "Inflation Rate Percentage",
        text: insuredQuote.inflationPercentage || "None"
      })
    );
  }

  const premiumFormatter = quote => {
    const premiumText = currencyFormat(quote.premium, 2);
    const modeText = modeFormat(quote.mode, "adverb");

    if (quote.payDuration === -1) {
      return `${premiumText} ${modeText} for Life`;
    } else if (quote.payDuration === -65) {
      return `${premiumText} ${modeText} until Age 65`;
    } else if (quote.payDuration === 1 && quote.mode === 1) {
      return `${premiumText} Single Pay`;
    } else if (quote.payDuration === 1) {
      return `${premiumText} ${modeText} for 1 Year`;
    }

    return `${premiumText} ${modeText} for ${quote.payDuration} Years`;
  };

  if (product.value.multiplePolicies && jointInsuredQuote?.premium) {
    productDetailItems.push(
      textItem({
        title: "Premium",
        text: [
          `${insured.value.name}'s ${premiumFormatter(insuredQuote)}`,
          `${jointInsured.value.name}'s ${premiumFormatter(jointInsuredQuote)}`
        ].join("\n")
      })
    );
  } else if (insuredQuote.premium) {
    productDetailItems.push(
      textItem({
        title: "Premium",
        text: premiumFormatter(insuredQuote)
      })
    );
  }

  if (insuredQuote.incomeAmount) {
    let text = `${currencyFormat(insuredQuote.incomeAmount, 0)}/yr`;
    if (insuredQuote.yearsDeferred) {
      const years = insuredQuote.yearsDeferred === 1 ? "year" : "years";
      text += ` after ${insuredQuote.yearsDeferred} ${years}`;
    }

    productDetailItems.push(
      textItem({
        title: "Income",
        text
      })
    );
  }

  if (insuredQuote.section1035Exchange.enabled && !isLTC) {
    productDetailItems.push(
      textItem({
        title: "Section 1035 Exchange",
        text: currencyFormat(insuredQuote.section1035Exchange.amount)
      })
    );
  }

  if (policyNumber.value) {
    productDetailItems.push(
      textItem({
        title: "Policy Number",
        text: policyNumber.value
      })
    );
  }

  if ([true, false].includes(hasPhysician.value)) {
    productDetailItems.push(
      textItem({
        title: "Using Human API",
        text: hasPhysician.value ? "Yes" : "No"
      })
    );
  }

  if (humanApiInvitedAt.value) {
    productDetailItems.push(
      textItem({
        title: "Human API Invited At",
        text: timestampFormatter(humanApiInvitedAt.value, "none", "date-time")
      })
    );
  }

  if ([true, false].includes(tia.value) && !isLTC) {
    productDetailItems.push(
      textItem({
        title: "Temporary Insurance",
        text: tia.value ? "Yes" : "No"
      })
    );
  }

  if (orderNumber.value) {
    productDetailItems.push(
      textItem({
        title: "Exam Number",
        text: orderNumber.value
      })
    );
  }

  if (examDate.value) {
    productDetailItems.push(
      textItem({
        title: "Exam Date",
        text: timestampFormatter(examDate.value, "none", "date-time")
      })
    );
  }

  if (!jointLtc && insured.value.birthdate) {
    insuredDetailItems.push(
      textItem({
        title: "Birthdate",
        text: timestampFormatter(insured.value.birthdate, "sole-day")
      })
    );
  }

  if (jointLtc) {
    const birthdateFormat = insured =>
      `${timestampFormatter(insured.birthdate, "sole-day")} (${insured.name})`;
    insuredDetailItems.push(
      textItem({
        title: "Birthdate",
        text: `${birthdateFormat(insured.value)}\n${birthdateFormat(
          jointInsured.value
        )}`
      })
    );
  }

  if (insuredQuote.rateClass && !jointLtc) {
    insuredDetailItems.push(
      textItem({
        title: "Initial Rating",
        text: insuredQuote.rateClass
      })
    );
  } else if (jointLtc) {
    insuredDetailItems.push(
      textItem({
        title: "Initial Rating",
        text: `${insuredQuote.rateClass} (${insured.value.name})\n${jointInsuredQuote.rateClass} (${jointInsured.value.name})`
      })
    );
  }

  if (insuredQuote.heightText && insuredQuote.weight && !jointLtc) {
    insuredDetailItems.push(
      textItem({
        title: "Build",
        text: `${insuredQuote.heightText} ${insuredQuote.weight}lbs\n${insuredQuote.buildText}`
      })
    );
  } else if (
    jointInsuredQuote?.heightText &&
    jointInsuredQuote?.weight &&
    insuredQuote.heightText &&
    insuredQuote.weight &&
    jointLtc
  ) {
    const text = [
      `${insuredQuote.heightText} ${insuredQuote.weight}lbs (${insured.value.name})`,
      insuredQuote.buildText,
      `${jointInsuredQuote.heightText} ${jointInsuredQuote.weight}lbs (${jointInsured.value.name})`,
      jointInsuredQuote.buildText
    ].join("\n");

    insuredDetailItems.push(
      textItem({
        title: "Build",
        text
      })
    );
  }

  if (jointLtc) {
    insuredDetailItems.push(
      textItem({
        title: "Gender",
        text: `${insuredQuote.gender} (${insured.value.name})\n${jointInsuredQuote.gender} (${jointInsured.value.name})`
      })
    );
  } else if (insuredQuote.gender) {
    insuredDetailItems.push(
      textItem({
        title: "Gender",
        text: insuredQuote.gender
      })
    );
  }

  const formatSmoker = (quote, name) => {
    const text = [];
    if (name) text.push(`${quote.smoker} (${name})`);
    else text.push(quote.smoker);
    if (quote.smoker !== "Never") {
      const addLastUseDate = quote.smoker === "Previously";
      quote.usages.forEach(s => {
        let t = `${s.category} ${formatFrequency(s.frequency)}`;
        if (addLastUseDate) {
          t += ` - Last: ${timestampFormatter(s.lastUseDate, "sole-day")}`;
        }
        text.push(t);
      });
    }
    return text.join("\n");
  };

  if (insuredQuote.smoker && !jointLtc) {
    insuredDetailItems.push(
      textItem({
        title: "Smoking Status",
        text: formatSmoker(insuredQuote)
      })
    );
  } else if (insuredQuote.smoker && jointInsuredQuote?.smoker && jointLtc) {
    insuredDetailItems.push(
      textItem({
        title: "Smoking Status",
        text: [
          formatSmoker(insuredQuote, insured.value.name),
          formatSmoker(jointInsuredQuote, jointInsured.value.name)
        ].join("\n")
      })
    );
  }

  if (examAddress.value) {
    insuredDetailItems.push(
      textItem({
        title: "Exam Address",
        text: examAddress.value
      })
    );
  }
  detailGroups.value.push(
    ...[metaDetailItems, productDetailItems, insuredDetailItems].filter(
      v => v.length
    )
  );
}

async function unlock() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    showErrorMessage: true,
    title: "Unlock eApp?",
    subtitle: "This will reset attempts to access the eApp",
    func: eAppStore.unlockEApp
  });
}

watchEffect(generateDetailGroups, { flush: "post" });
</script>
