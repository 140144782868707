<template>
  <v-card tile flat>
    <v-card-text>
      <v-row class="ma-0 align-center justify-space-between hidden-md-and-down">
        <v-col>
          <strong style="font-size: 1.2rem" data-testid="appointment-name">
            {{ name }}
          </strong>
          <br />
          Code
        </v-col>
        <v-spacer />
        <v-col>
          <v-row justify="end" class="px-3 pb-3">
            <carrier-image
              :id="carrier.id"
              :image="carrier.avatarUrl"
              contain
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0 justify-center align-center hidden-lg-and-up" dense>
        <div class="flex-column justify-center text-center">
          <div style="height: 100px; width: 300px">
            <carrier-image
              :id="carrier.id"
              :image="carrier.avatarUrl"
              contain
            />
          </div>
          <strong
            class="mt-3"
            style="font-size: 1.2rem"
            data-testid="appointment-name"
          >
            {{ name }}
          </strong>
          Code
        </div>
      </v-row>
      <template v-for="(detailGroup, index) in detailGroups" :key="index">
        <v-divider />
        <card-items :items="detailGroup" />
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CarrierImage from "@/components/shared/CarrierImage.vue";
import CardItems from "@/components/shared/card-items/CardItems.vue";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useAppointmentView } from "@/stores/appointment-view";
import { LINE_CODES } from "@/factories/Appointment";

import { getAgencyTin } from "@/api/agencies.service";
import { getAgentSsn } from "@/api/agents.service";

import {
  stringLowerComparison,
  timestampFormatter,
  numberFormat,
  getRouterLink,
  listToSentence
} from "@/util/helpers";

import {
  linkItem,
  routerLinkItem,
  sensitiveInfoItem,
  textItem
} from "@/components/shared/card-items/card-items";

const {
  compBuilder,
  ownable,
  carrier,
  compBuilders,
  assignment,
  status,
  ltcRider,
  states,
  appointmentManager,
  linesOfAuthority,
  productId,
  name,
  insuredName,
  appSignDate,
  daysPending,
  annualized,
  carrierAgreementType,
  followUpDate,
  updatedAt
} = storeToRefs(useAppointmentView());

const fullCompBuilder = computed(
  () =>
    compBuilders.value.find(({ value }) => compBuilder.value === value)?.text
);

const product = computed(() => {
  const text = carrier.value.products.find(
    ({ id }) => id === productId.value
  )?.name;
  return text || "No Product Exists for Appointment";
});

const cardDetails = computed(() => {
  const defaultDetails = [];

  if (ownable.value.id) {
    let text = ownable.value.name;
    if (!stringLowerComparison(ownable.value.legalName, ownable.value.name)) {
      text = `${text} (Legal Name: ${ownable.value.legalName})`;
    }
    defaultDetails.push(
      routerLinkItem({
        title: "Advisor",
        text,
        to: getRouterLink(ownable.value.type, ownable.value.id)
      })
    );
  }

  if (ownable.value.signerId) {
    defaultDetails.push(
      routerLinkItem({
        title: "Signer",
        text: ownable.value.signerName,
        to: getRouterLink(ownable.value.signerType, ownable.value.signerId)
      })
    );
  }

  if (
    assignment.value?.ownableId &&
    ownable.value.id !== assignment.value.ownableId
  ) {
    defaultDetails.push(
      routerLinkItem({
        title: "Assignment",
        text: `${assignment.value.ownableName} - ${assignment.value.name}`,
        to: getRouterLink("Appointment", assignment.value.id)
      })
    );
  }

  if (status.value) {
    defaultDetails.push(
      textItem({
        title: "Status",
        text: status.value
      })
    );
  }

  if (carrierAgreementType.value) {
    defaultDetails.push(
      textItem({
        title: "Commission Type",
        text: carrierAgreementType.value
      })
    );
  }

  if (ltcRider.value) {
    defaultDetails.push(
      textItem({
        title: "LTC Rider",
        text: "Yes"
      })
    );
  }

  if (Array.isArray(states.value)) {
    defaultDetails.push(
      textItem({
        title: "States",
        text: states.value.join(", ") || "None"
      })
    );
  }

  if (appointmentManager.value) {
    let text = appointmentManager.value.name;

    if (followUpDate.value) {
      text += ` - Next Follow Up: ${timestampFormatter(
        followUpDate.value,
        "sole-day",
        "basic"
      )}`;
    }

    defaultDetails.push(
      textItem({
        title: "Appointment Manager",
        text
      })
    );
  }

  if (fullCompBuilder.value) {
    defaultDetails.push(
      textItem({
        title: "Comp Builders",
        text: fullCompBuilder.value
      })
    );
  }

  if (linesOfAuthority.value.length) {
    defaultDetails.push(
      textItem({
        title: "Product",
        text: `${linesOfAuthority.value.join(", ")}: ${product.value}`
      })
    );
  }

  if (name.value && insuredName.value) {
    defaultDetails.push(
      textItem({
        title: "Applicant Name",
        text: insuredName.value
      })
    );
  }

  if (appSignDate.value) {
    defaultDetails.push(
      textItem({
        title: "Application Date",
        text: timestampFormatter(appSignDate.value, "sole-day", "basic")
      })
    );
  }

  if (daysPending.value || daysPending.value === 0) {
    defaultDetails.push(
      textItem({
        title: "Days Pending",
        text: numberFormat(daysPending.value)
      })
    );
  }

  if (updatedAt.value) {
    defaultDetails.push(
      textItem({
        title: "Last Update",
        text: timestampFormatter(updatedAt.value, null, "basic")
      })
    );
  }

  let title = "TIN";
  let type = "tin";
  if (ownable.value.type === "Agent") {
    title = "SSN";
    type = "ssn";
  }

  defaultDetails.push(
    sensitiveInfoItem({
      title,
      type,
      fetchFunc: () => {
        if (ownable.value.type === "Agent")
          return getAgentSsn(ownable.value.id);
        else if (ownable.value.type === "Agency")
          return getAgencyTin(ownable.value.id);
      }
    })
  );

  if (ownable.value.birthdate) {
    defaultDetails.push(
      textItem({
        title: "Advisor Birthdate",
        text: timestampFormatter(ownable.value.birthdate, "sole-day", "basic")
      })
    );
  }

  if (linesOfAuthority.value.length) {
    defaultDetails.push(
      textItem({
        title: "Lines of Authority",
        text: listToSentence(linesOfAuthority.value)
      })
    );
  }

  return defaultDetails;
});

const notableDetails = computed(() => {
  const details = [];
  if (annualized.value) {
    details.push(
      textItem({
        title: "Annualized",
        text: "Yes"
      })
    );
  }

  const isAnnuityLine = linesOfAuthority.value.includes(LINE_CODES.ANNUITY);
  if (carrier.value.annuityPhone && isAnnuityLine) {
    details.push(
      linkItem({
        title: "Annuity Licensing Phone",
        text: carrier.value.annuityPhone,
        href: `tel:${carrier.value.annuityPhone}`,
        dataOutboundType: "Carrier",
        dataOutboundId: carrier.value.id,
        dataOutboundNumber: carrier.value.annuityPhone
      })
    );
  }

  const isDisabilityLine = linesOfAuthority.value.includes(
    LINE_CODES.DISABILITY
  );
  if (carrier.value.disabilityPhone && isDisabilityLine) {
    details.push(
      linkItem({
        title: "Disability Licensing Phone",
        text: carrier.value.disabilityPhone,
        href: `tel:${carrier.value.disabilityPhone}`,
        dataOutboundType: "Carrier",
        dataOutboundId: carrier.value.id,
        dataOutboundNumber: carrier.value.disabilityPhone
      })
    );
  }

  const isLifeLine = linesOfAuthority.value.some(l =>
    [
      LINE_CODES.LIFE,
      LINE_CODES.LINKED_BENEFIT,
      LINE_CODES.VARIABLE_LIFE
    ].includes(l)
  );
  if (carrier.value.lifePhone && isLifeLine) {
    details.push(
      linkItem({
        title: "Life Licensing Phone",
        text: carrier.value.lifePhone,
        href: `tel:${carrier.value.lifePhone}`,
        dataOutboundType: "Carrier",
        dataOutboundId: carrier.value.id,
        dataOutboundNumber: carrier.value.lifePhone
      })
    );
  }

  const isLtcLine = linesOfAuthority.value.includes(LINE_CODES.LTC);
  if (carrier.value.ltcPhone && isLtcLine) {
    details.push(
      linkItem({
        title: "LTC Licensing Phone",
        text: carrier.value.ltcPhone,
        href: `tel:${carrier.value.ltcPhone}`,
        dataOutboundType: "Carrier",
        dataOutboundId: carrier.value.id,
        dataOutboundNumber: carrier.value.ltcPhone
      })
    );
  }

  return details;
});

const detailGroups = computed(() => {
  const groups = [];
  if (cardDetails.value.length) groups.push(cardDetails.value);
  if (notableDetails.value.length) groups.push(notableDetails.value);
  return groups;
});
</script>
