<template>
  <v-skeleton-loader v-if="loading" type="card" />
  <v-card v-else>
    <v-card-title class="font-weight-light text-h4" data-testid="employee-name">
      {{ employee.firstName }} {{ employee.lastName }}
    </v-card-title>
    <v-card-subtitle
      class="font-weight-light text-h6"
      data-testid="employee-position"
    >
      {{ employee.title }}, {{ employee.department }}
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <card-items :items="points" />
    </v-card-text>
    <v-divider />
    <v-card-title>
      <span class="font-weight-light text-h5"> Performance Evaluations </span>
      <v-spacer />
      <app-button
        class="text-none"
        color="primary"
        data-testid="add-review"
        @click="addReview"
      >
        <v-icon :icon="mdiPlus" /> Add Review
      </app-button>
    </v-card-title>
    <v-card-text>
      <v-timeline v-if="employee.reviews.length" align-top dense>
        <v-timeline-item
          v-for="({ reviewDate, review, reviewer }, index) in employee.reviews"
          :key="index"
          :data-testid="`review-${index}`"
          dot-color="primary"
        >
          <v-card variant="outlined">
            <v-card-title v-if="reviewDate" data-testid="review-title">
              {{ timestampFormatter(reviewDate, "sole-day") }}
            </v-card-title>
            <v-card-subtitle data-testid="review-reviewed-by">
              {{ reviewer }}
            </v-card-subtitle>
            <v-card-text data-testid="review-content">
              {{ review }}
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <p v-else data-testid="no-reviews">No Reviews</p>
    </v-card-text>
  </v-card>
</template>

<script setup>
import EmployeeMaxCasesDialog from "@/components/employees/EmployeeMaxCasesDialog.vue";
import CardItems from "@/components/shared/card-items/CardItems.vue";

import { getEmployee } from "@/api/employees.service";
import {
  parseErrorMessage,
  timestampFormatter,
  numberFormat
} from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { parse } from "date-fns";
import { useHead } from "@unhead/vue";
import { computed, markRaw, ref } from "vue";
import { useRouter } from "vue-router";
import { Employee } from "@/factories/Employee";
import { mdiPlus } from "@mdi/js";
import { textItem } from "@/components/shared/card-items/card-items";

useHead({ title: "Employee" });

const props = defineProps({
  id: { type: [Number, String], required: true }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const router = useRouter();
const employee = ref(Employee());
const loading = ref(false);
const points = computed(() => {
  const points = [
    textItem({
      title: "Anniversary",
      text: timestampFormatter(employee.value.anniversary, "sole-day")
    }),
    textItem({
      title: "Contract Type",
      text: employee.value.contractType
    }),
    textItem({
      title: "Status",
      text: employee.value.status
    })
  ];

  let maxCases = "N/A";
  if (![null, undefined].includes(employee.value.maxCases)) {
    maxCases = numberFormat(employee.value.maxCases);
  }

  points.push(
    textItem({
      title: "Max Cases",
      text: maxCases,
      onClick: async () => {
        const dialog = useDialogStore();
        const res = await dialog.showDialog({
          component: markRaw(EmployeeMaxCasesDialog),
          employeeId: +props.id,
          maxCases: employee.value.maxCases
        });
        if ("maxCases" in res) employee.value.maxCases = res.maxCases;
      }
    })
  );
  if (employee.value.manager?.name) {
    points.push(
      textItem({
        title: "Manager",
        text: employee.value.manager?.name
      })
    );
  }
  if (employee.value.terminatedDate) {
    points.push(
      textItem({
        title: "Terminated",
        text: timestampFormatter(employee.value.terminatedDate, "sole-day")
      })
    );
  }

  return points;
});

async function fetchEmployee() {
  loading.value = true;
  try {
    const res = await getEmployee(props.id);
    employee.value = res;
    sortReviews();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    await router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}
function addReview() {
  dialog
    .showDialog({
      component: "EmployeeReviewDialog",
      name: `${employee.value.firstName} ${employee.value.lastName}`,
      id: +props.id,
      persistent: true
    })
    .then(({ newReview }) => {
      if (!newReview) return;
      employee.value.reviews.push(newReview);
      sortReviews();
    });
}

function sortReviews() {
  employee.value.reviews.sort(
    (a, b) =>
      parse(b.reviewDate, "yyyy-MM-dd", new Date()) -
      parse(a.reviewDate, "yyyy-MM-dd", new Date())
  );
}

fetchEmployee();
</script>
