<template>
  <v-card>
    <v-row class="ma-0 pr-3" align="center">
      <v-card-title class="py-3"> To-Do Completion </v-card-title>
      <v-spacer />
      <app-button
        :icon="mdiOpenInNew"
        color="primary"
        data-testid="view-todos"
        variant="text"
        :to="{ name: 'Tables', query: { page: 'todos' } }"
        target="_blank"
      />
    </v-row>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col v-for="stat in stats" :key="stat.title" cols="6" md="4">
          <v-card
            flat
            color="section"
            :class="stat.class"
            :data-testid="stat.dataTestid"
          >
            <v-card-title class="text-center">
              <v-fade-transition mode="out-in">
                <v-progress-circular
                  v-if="loading"
                  key="loading"
                  indeterminate
                  width="2"
                />
                <span v-else key="loaded">
                  {{ stat.value }}
                </span>
              </v-fade-transition>
            </v-card-title>
            <v-card-subtitle class="text-center">
              {{ stat.title }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { mdiOpenInNew } from "@mdi/js";
import { getPersonalTodoReport } from "@/api/reports.service";
import { numberFormat, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed, ref } from "vue";

const snackbar = useSnackbarStore();

const averageDuration = ref();
const incomplete = ref();
const score = ref();
const total = ref();
const loading = ref(false);

const stats = computed(() => {
  let scoreTitle = "Score";
  if (!loading.value) {
    const totalCompleted = total.value - incomplete.value;
    const totalCompletedFormatted = numberFormat(totalCompleted);
    const totalFormatted = numberFormat(total.value);
    scoreTitle = `Score (${totalCompletedFormatted}/${totalFormatted})`;
  }
  return [
    {
      title: scoreTitle,
      value: `${score.value}%`,
      class: "score",
      dataTestid: "score"
    },
    {
      title: "Incomplete",
      value: incomplete.value,
      class: "incomplete-count",
      dataTestid: "incomplete-count"
    },
    {
      title: "Avg. Duration",
      value:
        averageDuration.value === 1
          ? `${averageDuration.value} day`
          : `${averageDuration.value} days`,
      class: "average-duration",
      dataTestid: "average-duration"
    }
  ];
});

async function getData() {
  try {
    loading.value = true;
    const result = await getPersonalTodoReport();
    averageDuration.value = result.averageDuration;
    incomplete.value = result.incomplete;
    score.value = result.score;
    total.value = result.total;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

getData();
</script>

<style lang="scss">
.v-application {
  .v-card.score,
  .v-card.incomplete-count,
  .v-card.average-duration {
    border-width: 1px !important;
    border-left-width: 5px !important;
    border-style: solid !important;
  }
  .v-card.score {
    border-color: green !important;
  }
  .v-card.incomplete-count {
    border-color: blue !important;
  }
  .v-card.average-duration {
    border-color: orange !important;
  }
}
</style>
