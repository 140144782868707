<template>
  <v-row v-if="loading" class="ma-0">
    <v-col> <v-skeleton-loader type="card" /> </v-col>
  </v-row>
  <div v-else class="ma-1 bg-surface">
    <appointment-card />
    <appointment-actions />
    <view-tabs v-model="activeTab" :tabs="tabs" />
  </div>
</template>

<script setup>
import ViewTabs from "@/components/shared/ViewTabs.vue";
import AppointmentAccesses from "@/components/appointments/AppointmentAccesses.vue";
import AppointmentActions from "@/components/appointments/AppointmentActions.vue";
import AppointmentCard from "@/components/appointments/AppointmentCard.vue";
import AppointmentCases from "@/components/appointments/AppointmentCases.vue";
import AppointmentChat from "@/components/appointments/AppointmentChat.vue";
import AppointmentContracting from "@/components/appointments/AppointmentContracting.vue";
import AppointmentDocuments from "@/components/appointments/AppointmentDocuments.vue";
import AppointmentDetails from "@/components/appointments/AppointmentDetails.vue";
import AppointmentHierarchy from "@/components/appointments/AppointmentHierarchy.vue";
import AppointmentSubAgents from "@/components/appointments/AppointmentSubAgents.vue";
import AppointmentTimeline from "@/components/appointments/AppointmentTimeline.vue";
import AppointmentToDoList from "@/components/appointments/AppointmentToDoList.vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useInstanceStore } from "@/stores/instance";
import { useAppointmentView } from "@/stores/appointment-view";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

import { useHead } from "@unhead/vue";
import { computed, markRaw, onBeforeUnmount, ref, toRef, watch } from "vue";
import {
  mdiAccountMultiple,
  mdiCheck,
  mdiClock,
  mdiFileDocumentEdit,
  mdiFileMultiple,
  mdiMessage,
  mdiShieldAccount,
  mdiSitemap,
  mdiWrench
} from "@mdi/js";

import { useRouter } from "vue-router";
import { useFullEmailViewer } from "@/composables/full-email.composable";

const props = defineProps({
  id: {
    type: [String, Number],
    required: true
  },
  page: {
    type: String,
    default: ""
  },
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  },
  highlightNote: {
    type: Number,
    required: false,
    default: null
  }
});
const highlightTodo = toRef(props, "highlightTodo");

const snackbar = useSnackbarStore();
const appointment = useAppointmentView();
const { name, carrier, todoCount } = storeToRefs(appointment);

const { breadcrumb } = storeToRefs(useInstanceStore());

const { isGroupTwoPlus } = storeToRefs(useUserStore());

const loading = ref(false);
const activeTab = ref(0);

const head = useHead({ title: "Appointment Viewer" });

onBeforeUnmount(() => {
  breadcrumb.value = "";
  appointment.$dispose();
});

async function fetchAppointment(hideLoader = false) {
  try {
    if (!hideLoader) loading.value = true;
    await appointment.fetchAppointment(props.id);

    if (carrier.value.id && isGroupTwoPlus.value) {
      appointment.fetchCompBuilders();
      appointment.fetchProducts();
    }

    setMetaInfo();
    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;

    if (props.highlightNote) {
      router.replace({ query: { "highlight-note": undefined } });
      const fullEmailViewer = useFullEmailViewer(props.highlightNote);
      fullEmailViewer.retrieveFullEmail();
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    await router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

function setMetaInfo() {
  breadcrumb.value = name.value;
  head.patch({ title: name.value });
}

const tabs = computed(() => {
  const availableTabs = [
    {
      icon: mdiMessage,
      component: markRaw(AppointmentChat),
      title: "Chat",
      page: "chat",
      eager: true
    },
    {
      icon: mdiFileDocumentEdit,
      component: markRaw(AppointmentDetails),
      title: "Details",
      page: "details",
      componentProps: {
        onRefresh: () => fetchAppointment(true)
      }
    },
    {
      icon: mdiAccountMultiple,
      component: markRaw(AppointmentCases),
      title: "Cases",
      page: "cases"
    },
    {
      icon: mdiSitemap,
      component: markRaw(AppointmentHierarchy),
      title: "Hierarchy",
      page: "hierarchy"
    },
    {
      icon: mdiAccountMultiple,
      component: markRaw(AppointmentSubAgents),
      title: "Sub-Agents",
      page: "sub-agents"
    },
    {
      icon: mdiShieldAccount,
      component: markRaw(AppointmentAccesses),
      title: "Access",
      page: "access"
    }
  ];

  if (isGroupTwoPlus.value) {
    availableTabs.push(
      {
        icon: mdiWrench,
        component: markRaw(AppointmentContracting),
        title: "Contracting",
        page: "contracting"
      },
      {
        icon: mdiFileMultiple,
        component: markRaw(AppointmentDocuments),
        title: "Documents",
        page: "documents"
      }
    );
  }

  availableTabs.push(
    {
      icon: mdiCheck,
      title: "To-Do",
      component: markRaw(AppointmentToDoList),
      page: "to-do",
      componentProps: {
        highlightTodo: highlightTodo.value
      },
      eager: true,
      badgeProps: todoCount.value
        ? {
            floating: true,
            "offset-y": 3,
            color: "error",
            modelValue: Boolean(todoCount.value),
            content: todoCount.value,
            "data-testid": "todo-badge"
          }
        : null
    },
    {
      icon: mdiClock,
      title: "Timeline",
      component: markRaw(AppointmentTimeline),
      page: "timeline"
    }
  );

  return availableTabs;
});

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

const router = useRouter();
function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

fetchAppointment(false);
</script>
