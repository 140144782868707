<template>
  <v-navigation-drawer class="d-print-none" v-bind="sidebarProps">
    <v-row
      class="hidden-sm-and-down ma-0 align-center justify-center"
      style="height: 50px"
    >
      <app-logo />
    </v-row>

    <user-menu sidebar class="hidden-lg-and-up" />

    <sidebar-navigation-items />
  </v-navigation-drawer>
</template>

<script setup>
import { computed } from "vue";
import AppLogo from "@/components/AppLogo.vue";

import SidebarNavigationItems from "@/components/SidebarNavigationItems.vue";
import UserMenu from "@/components/UserMenu.vue";
import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";

const { lgAndUp, smAndDown } = useDisplay();
const instance = useInstanceStore();
const router = useRouter();
const { fullscreen, mobileSidebarVisible } = storeToRefs(instance);
const isMini = computed(() => router.currentRoute.value.meta?.miniSidebar);

const sidebarProps = computed(() => {
  if (lgAndUp.value) {
    return {
      modelValue: !fullscreen.value,
      rail: isMini.value,
      expandOnHover: isMini.value,
      railWidth: "64"
    };
  }

  return {
    modelValue: mobileSidebarVisible.value,
    "onUpdate:modelValue": v => (mobileSidebarVisible.value = v),
    right: smAndDown.value
  };
});
</script>
