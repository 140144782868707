<template>
  <v-card tile flat color="section">
    <v-data-table
      v-model:sort-by="table.options.value.sortBy"
      v-model:items-per-page="table.options.value.itemsPerPage"
      v-model:page="table.options.value.page"
      class="transparent-data-table"
      data-testid="carrier-documents-table"
      :items="table.mappedItems.value"
      :headers="table.tableHeaders.value"
      :mobile="null"
      mobile-breakpoint="sm"
    >
      <template v-if="user.isGroupTwoPlus" #top>
        <v-row class="pa-3 ma-0">
          <v-spacer />
          <app-button
            data-testid="add-document-button"
            class="text-none"
            color="accent"
            @click="createCarrierDocument"
          >
            <v-icon :icon="mdiPlus" />Add Document
          </app-button>
        </v-row>
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="flex-row">
          <app-button
            data-testid="download-button"
            :icon="mdiDownload"
            color="primary"
            variant="text"
            density="comfortable"
            v-bind="downloadCarrierDocument(item.additional)"
          />
          <app-button
            v-if="user.isGroupTwoPlus"
            :icon="mdiPencil"
            color="accent"
            data-testid="edit-button"
            variant="text"
            density="comfortable"
            @click="updateCarrierDocument(item.additional)"
          />
          <app-button
            v-if="user.isGroupTwoPlus"
            color="error"
            :icon="mdiDelete"
            data-testid="delete-button"
            variant="text"
            density="comfortable"
            @click="deleteCarrierDocument(item.additional)"
          />
        </div>
      </template>
      <template #[`item.publishDate`]="{ item }">
        <timestamp-formatter
          format="date-time"
          :model-value="item.publishDate"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script setup>
import { mdiPlus, mdiDownload, mdiPencil, mdiDelete } from "@mdi/js";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import DocumentDialog from "@/dialogs/DocumentDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { CARRIER_DOCUMENT_CATEGORIES } from "@/factories/Carrier";

import { markRaw } from "vue";
import { downloadFileAsLink } from "@/util/helpers";
import { useUserStore } from "@/stores/user";

import {
  deleteDocument,
  getDocumentUrl,
  updateDocument
} from "@/api/documents.service";
import { useDialogStore } from "@/stores/dialog";

import { useTable } from "@/composables/table.composable";
import { storeToRefs } from "pinia";
import { useCarrierView } from "@/stores/carrier-view";
import { useDisplay } from "vuetify/lib/framework.mjs";

const carrierView = useCarrierView();
const { documents } = storeToRefs(carrierView);

const user = useUserStore();
const dialog = useDialogStore();
const { mdAndDown } = useDisplay();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name"
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category"
    }),
    new TableHeader({
      text: "Uploaded By",
      value: "uploadedBy",
      map: "ownable.name"
    }),
    new TableHeader({
      text: "Publish Date",
      value: "publishDate",
      map: "createdAt",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ],
  options: TableOptions({ sortBy: [{ key: "publishDate", order: "desc" }] })
});

function setItems() {
  table.items.value.splice(0, table.items.value.length);
  table.items.value.push(...documents.value);
}

function downloadCarrierDocument(item) {
  return downloadFileAsLink(getDocumentUrl(item.uid), mdAndDown.value);
}

async function deleteCarrierDocument(row) {
  if (!user.isGroupTwoPlus) return;
  const res = await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Remove ${row.name} from this Case?`,
    subtitle: "This cannot be undone",
    func: () => deleteDocument(row.uid)
  });
  if (!res?.confirm) return;

  const index = table.items.value.findIndex(val => val.uid === row.uid);

  if (index === -1) return;
  table.items.value.splice(index, 1);
}

async function updateCarrierDocument(row) {
  if (!user.isGroupTwoPlus) return;
  dialog.showDialog({
    component: markRaw(DocumentDialog),
    scrollable: true,
    categories: CARRIER_DOCUMENT_CATEGORIES,
    title: "Update Carrier Document",
    documentId: row.uid,
    documentNameValue: row.name,
    documentCategoryValue: row.category,
    single: true,
    func: async ({ name, category }) => {
      await updateDocument(row.uid, { name, category });

      const index = table.items.value.findIndex(v => v.uid === row.uid);
      if (index === -1) return;
      table.items.value[index].name = name;
      table.items.value[index].category = category;
    }
  });
}

async function createCarrierDocument() {
  dialog.showDialog({
    component: markRaw(DocumentDialog),
    scrollable: true,
    title: "Add Carrier Document",
    categories: CARRIER_DOCUMENT_CATEGORIES,
    single: true,
    func: async ({ category, file }) => {
      const res = await carrierView.uploadDocument({ category, file });
      table.items.value.unshift(res);
    }
  });
}

setItems();
</script>
