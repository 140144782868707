<template>
  <side-by-side-tab-controller
    v-model="activeTaskTab"
    :show-side-by-side="mdAndUp"
    :expanded="expanded"
  >
    <template #left>
      <task-list
        v-model="activeTaskKey"
        :expanded="expanded"
        :tasks-grouped-by-category="groupedTasks"
        v-bind="taskListProps"
        @same-task-clicked="activeTaskTab = 1"
        @toggle-expand="expanded = !expanded"
      >
        <template #title>
          <span v-if="incompleteTaskCount > 0" style="word-wrap: normal">
            <v-icon
              color="error"
              class="ml-2 mb-1 mr-1"
              size="20"
              :icon="mdiAlert"
            />
            {{ completeTaskCount }} of {{ totalTaskCount }}
            {{ totalTaskCount === 1 ? "Task" : "Tasks" }}
            Complete
          </span>
          <span v-else>
            <v-icon color="success" class="ml-2 mb-1" :icon="mdiCheckCircle" />
            All Tasks Complete
          </span>
        </template>
      </task-list>
    </template>
    <template #right>
      <v-scroll-y-transition mode="out-in">
        <case-view-task
          v-if="activeTaskKey"
          :key="activeTaskKey"
          :loading="loading"
        >
          <template v-if="smAndDown" #back-button>
            <app-button
              variant="outlined"
              color="accent"
              class="text-none mx-3 mt-3"
              @click="activeTaskTab = 0"
            >
              <v-icon :icon="mdiArrowLeft" /> View Tasks
            </app-button>
          </template>
        </case-view-task>
        <v-card v-else key="no-active-task" class="h-100" flat>
          <v-col align="center" justify="center" class="h-100">
            <v-row align="center" justify="center" class="h-100">
              <div>
                <v-card-title>
                  <v-spacer /> No Task Selected <v-spacer />
                </v-card-title>
                <v-card-subtitle>
                  Use the menu on the left to select a task.
                </v-card-subtitle>
              </div>
            </v-row>
          </v-col>
        </v-card>
      </v-scroll-y-transition>
    </template>
  </side-by-side-tab-controller>
</template>
<script setup>
import TaskList from "@/components/shared/tasks/TaskList.vue";
import CaseViewTask from "@/components/cases/case-view/CaseViewTask.vue";
import SideBySideTabController from "@/components/shared/SideBySideTabController.vue";
import CreateRequirementDialog from "@/components/cases/case-dialogs/CreateRequirementDialog.vue";

import { useCaseViewStore } from "@/stores/case-view";
import { useCaseViewActiveTaskStore } from "@/stores/case-view-active-task";
import { computed, ref, watch, toRef, markRaw } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useDialogStore } from "@/stores/dialog";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import {
  mdiAlert,
  mdiCheckCircle,
  mdiArrowLeft,
  mdiExclamation,
  mdiCheck,
  mdiEyeOff
} from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";

const props = defineProps({ active: Boolean });
const pageIsActive = toRef(props, "active");

const { mdAndUp, mdAndDown, smAndDown } = useDisplay();
const router = useRouter();

const user = useUserStore();
const activeTaskStore = useCaseViewActiveTaskStore();
const caseViewStore = useCaseViewStore();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const { activeTaskKey } = storeToRefs(activeTaskStore);

const {
  incompleteTaskCount,
  completeTaskCount,
  incompleteTasks,
  completeTasks,
  hiddenTasks,
  requirements,
  id: caseId,
  carrier,
  contractParties,
  room,
  timeline,
  assignOptions,
  insured,
  agents
} = storeToRefs(caseViewStore);

const activeTaskTab = ref(activeTaskKey.value ? 1 : 0);
const expanded = ref(false);
const loading = ref(false);

const totalTaskCount = computed(
  () => incompleteTaskCount.value + completeTaskCount.value
);

const groupedTasks = computed(() => {
  const groups = [
    {
      icon: mdiExclamation,
      text: "Incomplete",
      tasks: incompleteTasks.value,
      defaultOpen: Boolean(incompleteTaskCount.value)
    },
    {
      icon: mdiCheck,
      text: "Complete",
      tasks: completeTasks.value
    }
  ];
  if (user.isGroupTwoPlus) {
    groups.push({
      icon: mdiEyeOff,
      text: "Hidden",
      tasks: hiddenTasks.value
    });
  }

  return groups;
});

watch(activeTaskKey, (newV, oldV) => {
  if (oldV === newV) return;
  if (newV) viewTask(newV);
  else syncQueryToTask(null);
});

async function viewTask(key) {
  syncQueryToTask(key);
  if (mdAndDown.value) activeTaskTab.value = 1;

  const req = requirements.value.find(v => v.key === key);

  try {
    loading.value = true;
    await activeTaskStore.initializeActiveTask(
      caseId.value,
      key,
      req.id,
      req.type
    );
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

function syncQueryToTask(key) {
  if (!router) return;
  if (router.currentRoute.value.query.task !== key) {
    const query = { page: "tasks" };
    if (key) query.task = key;
    router.replace({ query });
  }
}

const taskListProps = computed(() => {
  if (user.isGroupOne) return {};
  return {
    onClick: createRequirement
  };
});

async function createRequirement() {
  const response = await dialog.showDialog({
    component: markRaw(CreateRequirementDialog),
    carrier: carrier.value,
    contractParties: contractParties.value,
    room: room.value,
    timeline: timeline.value,
    assignOptions: assignOptions.value,
    caseId: caseId.value,
    scrollable: true,
    width: "1200px",
    insured: insured.value,
    agent: agents.value?.[0]
  });

  if (response?.task) {
    requirements.value.push(response.task);
    viewTask(response.task.key);
  }
}

watch(pageIsActive, v => {
  if (v) syncQueryToTask(activeTaskKey.value);
});
</script>
