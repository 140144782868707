import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import CreateAffiliationDialog from "@/dialogs/CreateAffiliationDialog.vue";
import CreateFormDialog from "@/dialogs/CreateFormDialog.vue";
import ContractingQuestionExplanationDialog from "@/dialogs/ContractingQuestionExplanationDialog.vue";
import CreateTrainingDialog from "@/dialogs/CreateTrainingDialog.vue";

import EmployeeReviewDialog from "@/dialogs/EmployeeReviewDialog.vue";
import EmploymentHistoryDialog from "@/dialogs/EmploymentHistoryDialog.vue";
import HelpCenterReader from "@/components/help-center/HelpCenterReader.vue";
import MarketingManagerEditDialog from "@/dialogs/MarketingManagerEditDialog.vue";
import NewAgencyDialog from "@/dialogs/NewAgencyDialog.vue";
import NewCommissionAssignmentDialog from "@/dialogs/NewCommissionAssignmentDialog.vue";

import SmokingTypeDialog from "@/dialogs/SmokingTypeDialog.vue";
import WelcomeTourDialog from "@/dialogs/WelcomeTourDialog.vue";

import TextEditDialog from "@/dialogs/TextEditDialog.vue";

export default {
  ConfirmationDialog,
  CreateAffiliationDialog,
  CreateFormDialog,
  ContractingQuestionExplanationDialog,
  CreateTrainingDialog,
  EmployeeReviewDialog,
  EmploymentHistoryDialog,
  HelpCenterReader,
  MarketingManagerEditDialog,
  NewAgencyDialog,
  NewCommissionAssignmentDialog,
  SmokingTypeDialog,
  WelcomeTourDialog,
  TextEditDialog
};
