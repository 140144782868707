<template>
  <v-col v-if="loading" cols="12" class="pa-1">
    <v-skeleton-loader type="card" />
    <v-skeleton-loader type="card" />
  </v-col>
  <div v-else class="ma-1 bg-surface">
    <contract-party-view-card>
      <contract-party-view-actions />
    </contract-party-view-card>
    <view-tabs v-model="activeTab" :tabs="tabs" />
  </div>
</template>

<script setup>
import ViewTabs from "@/components/shared/ViewTabs.vue";
import ContractPartyViewCard from "@/components/contract-parties/contract-party-view/ContractPartyViewCard.vue";
import ContractPartyViewActions from "@/components/contract-parties/contract-party-view/ContractPartyViewActions.vue";
import ContractPartyViewAccess from "@/components/contract-parties/contract-party-view/ContractPartyViewAccess.vue";
import ContractPartyViewDetailsIndividual from "@/components/contract-parties/contract-party-view/ContractPartyViewDetailsIndividual.vue";
import ContractPartyViewDetailsEntity from "@/components/contract-parties/contract-party-view/ContractPartyViewDetailsEntity.vue";
import ContractPartyViewCases from "@/components/contract-parties/contract-party-view/ContractPartyViewCases.vue";
import ContractPartyViewChat from "@/components/contract-parties/contract-party-view/ContractPartyViewChat.vue";
import ContractPartyViewToDoList from "@/components/contract-parties/contract-party-view/ContractPartyViewToDoList.vue";

import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { storeToRefs } from "pinia";
import { useHead } from "@unhead/vue";
import {
  mdiChartBar,
  mdiCheck,
  mdiFileDocumentEdit,
  mdiMessage,
  mdiShieldAccount
} from "@mdi/js";
import { markRaw, onBeforeUnmount, ref, watch, computed, toRef } from "vue";
import { parseErrorMessage } from "@/util/helpers";
import { useRouter } from "vue-router";
import { useContractPartyView } from "@/stores/contract-party-view";
import { useFullEmailViewer } from "@/composables/full-email.composable";

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  page: {
    type: String,
    required: false,
    default: null
  },
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  },
  highlightNote: {
    type: Number,
    required: false,
    default: null
  }
});
const highlightTodo = toRef(props, "highlightTodo");

const contractPartyView = useContractPartyView();
contractPartyView.$reset();
const router = useRouter();
const snackbar = useSnackbarStore();
const instance = useInstanceStore();
const { breadcrumb } = storeToRefs(instance);
const head = useHead({ title: "Client Viewer" });
const { displayName, isIndividual, todoCount } = storeToRefs(contractPartyView);

const activeTab = ref(0);
const loading = ref(false);

const tabs = computed(() => {
  return [
    {
      icon: mdiMessage,
      title: "Notes",
      page: "notes",
      eager: true,
      component: markRaw(ContractPartyViewChat)
    },
    {
      icon: mdiCheck,
      title: "To-Do",
      page: "to-do",
      component: markRaw(ContractPartyViewToDoList),
      componentProps: {
        highlightTodo: highlightTodo.value
      },
      eager: true,
      badgeProps: todoCount.value
        ? {
            floating: true,
            "offset-y": 3,
            color: "error",
            modelValue: Boolean(todoCount.value),
            content: todoCount.value,
            "data-testid": "todo-badge"
          }
        : null
    },
    {
      icon: mdiChartBar,
      title: "Cases",
      page: "cases",
      component: markRaw(ContractPartyViewCases)
    },
    {
      icon: mdiFileDocumentEdit,
      title: "Details",
      page: "details",
      default: true,
      component: markRaw(
        isIndividual.value
          ? ContractPartyViewDetailsIndividual
          : ContractPartyViewDetailsEntity
      )
    },
    {
      icon: mdiShieldAccount,
      title: "Access",
      page: "access",
      component: markRaw(ContractPartyViewAccess)
    }
  ];
});

async function getContractPartyInfo() {
  try {
    loading.value = true;
    await contractPartyView.initialize(props.id);
    breadcrumb.value = displayName.value;
    head.patch({ title: displayName.value });

    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;
    else activeTab.value = tabs.value.findIndex(v => v.default) || 0;

    if (props.highlightNote) {
      router.replace({ query: { "highlight-note": undefined } });
      const fullEmailViewer = useFullEmailViewer(props.highlightNote);
      fullEmailViewer.retrieveFullEmail();
    }
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: 6000
    });
    await router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

getContractPartyInfo();

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

onBeforeUnmount(() => (breadcrumb.value = ""));
</script>
