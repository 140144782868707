<template>
  <v-card>
    <v-card-title>
      {{ props.title }}
    </v-card-title>
    <v-card-text class="px-2">
      <to-do-item-view
        :id="props.id"
        ref="itemView"
        v-model:loading="loading"
        v-model:is-complete="isComplete"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Close
      </app-button>
      <app-button
        class="text-none"
        color="accent"
        data-testid="edit-todo-in-dialog"
        @click="editTodo"
      >
        Edit ToDo
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="toggle-completeness"
        :loading="loading"
        @click="markCompleteness"
      >
        Mark as {{ isComplete ? "Incomplete" : "Complete" }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import ToDoItemView from "@/components/todo/ToDoItemView.vue";
import ToDoItemDialog from "@/components/todo/ToDoItemDialog.vue";

import { useDialogStore } from "@/stores/dialog";
import { markRaw, ref } from "vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { updateTodo } from "@/api/todos.service";
const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  }
});

const snackbar = useSnackbarStore();

const itemView = ref(""); // templateref

const isComplete = ref(false);
const loading = ref(false);

const dialog = useDialogStore();

async function editTodo() {
  const res = await dialog.showDialog({
    component: markRaw(ToDoItemDialog),
    scrollable: true,
    persistent: true,
    modelValue: { id: props.id }
  });

  if (res?.id) fetchTodo();
}

async function markCompleteness() {
  try {
    loading.value = true;
    await updateTodo(props.id, { complete: !isComplete.value });
    fetchTodo();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

function fetchTodo() {
  itemView.value.fetchTodo();
}
</script>
